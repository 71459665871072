const data2 = {
  data: {
    sentenceRangeMap: [
      {
        id: 0,
        type: "video",
        start: 0,
        end: 5,
        startTime: 0.15,
        endTime: 2.15,
        info: {
          url: "https://res.cloudinary.com/dlmsxlf2n/video/upload/v1717684301/Diveo/Assets/videos/stock-vid3.mp4",
          height: 720,
          width: 1280,
        },
      },
      {
        id: 1,
        type: "video",
        start: 6,
        end: 17,
        startTime: 2.91,
        endTime: 7.31,
        info: {
          url: "https://res.cloudinary.com/dlmsxlf2n/video/upload/v1717684301/Diveo/Assets/videos/stock-vid2.mp4",
          height: 720,
          width: 1365,
        },
      },
      {
        id: 2,
        type: "video",
        start: 18,
        end: 28,
        startTime: 8.03,
        endTime: 11.75,
        info: {
          url: "https://res.cloudinary.com/dlmsxlf2n/video/upload/v1717684301/Diveo/Assets/videos/stock-vid3.mp4",
          height: 720,
          width: 1280,
        },
      },
      {
        id: 3,
        type: "video",
        start: 29,
        end: 45,
        startTime: 12.63,
        endTime: 18.75,
        info: {
          url: "https://res.cloudinary.com/dlmsxlf2n/video/upload/v1717684301/Diveo/Assets/videos/stock-vid2.mp4",
          height: 720,
          width: 1365,
        },
      },
      {
        id: 4,
        type: "video",
        start: 46,
        end: 59,
        startTime: 19.51,
        endTime: 24.27,
        info: {
          url: "https://res.cloudinary.com/dlmsxlf2n/video/upload/v1717684301/Diveo/Assets/videos/stock-vid3.mp4",
          height: 720,
          width: 1280,
        },
      },
      {
        id: 5,
        type: "video",
        start: 60,
        end: 74,
        startTime: 25.03,
        endTime: 29.51,
        info: {
          url: "https://res.cloudinary.com/dlmsxlf2n/video/upload/v1717684301/Diveo/Assets/videos/stock-vid2.mp4",
          height: 720,
          width: 1365,
        },
      },
      {
        id: 6,
        type: "video",
        start: 75,
        end: 85,
        startTime: 30.27,
        endTime: 34.67,
        info: {
          url: "https://res.cloudinary.com/dlmsxlf2n/video/upload/v1717684301/Diveo/Assets/videos/stock-vid3.mp4",
          height: 720,
          width: 1280,
        },
      },
      {
        id: 7,
        type: "video",
        start: 86,
        end: 97,
        startTime: 35.47,
        endTime: 39.47,
        info: {
          url: "https://res.cloudinary.com/dlmsxlf2n/video/upload/v1717684301/Diveo/Assets/videos/stock-vid2.mp4",
          height: 720,
          width: 1365,
        },
      },
    ],
    wordLevelTimestamps: [
      {
        wordStart: 0,
        wordEnd: 0,
        word: "5",
        mappedWord: "five",
        wordStartTime: 0.15,
        wordEndTime: 0.43,
      },
      {
        wordStart: 1,
        wordEnd: 1,
        word: "simple",
        mappedWord: "simple",
        wordStartTime: 0.43,
        wordEndTime: 0.75,
      },
      {
        wordStart: 2,
        wordEnd: 2,
        word: "ways",
        mappedWord: "ways",
        wordStartTime: 0.75,
        wordEndTime: 1.03,
      },
      {
        wordStart: 3,
        wordEnd: 3,
        word: "to",
        mappedWord: "to",
        wordStartTime: 1.03,
        wordEndTime: 1.15,
      },
      {
        wordStart: 4,
        wordEnd: 4,
        word: "reduce",
        mappedWord: "reduce",
        wordStartTime: 1.15,
        wordEndTime: 1.51,
      },
      {
        wordStart: 5,
        wordEnd: 5,
        word: "stress",
        mappedWord: "stress",
        wordStartTime: 1.51,
        wordEndTime: 2.15,
      },
      {
        wordStart: 6,
        wordEnd: 6,
        word: "practice",
        mappedWord: "practice",
        wordStartTime: 2.91,
        wordEndTime: 3.43,
      },
      {
        wordStart: 7,
        wordEnd: 7,
        word: "deep",
        mappedWord: "deep",
        wordStartTime: 3.43,
        wordEndTime: 3.67,
      },
      {
        wordStart: 8,
        wordEnd: 8,
        word: "breathing",
        mappedWord: "breathing",
        wordStartTime: 3.67,
        wordEndTime: 4.11,
      },
      {
        wordStart: 9,
        wordEnd: 9,
        word: "exercises",
        mappedWord: "exercises",
        wordStartTime: 4.11,
        wordEndTime: 5.07,
      },
      {
        wordStart: 10,
        wordEnd: 10,
        word: "to",
        mappedWord: "to",
        wordStartTime: 5.07,
        wordEndTime: 5.23,
      },
      {
        wordStart: 11,
        wordEnd: 11,
        word: "calm",
        mappedWord: "calm",
        wordStartTime: 5.23,
        wordEndTime: 5.47,
      },
      {
        wordStart: 12,
        wordEnd: 12,
        word: "the",
        mappedWord: "the",
        wordStartTime: 5.47,
        wordEndTime: 5.59,
      },
      {
        wordStart: 13,
        wordEnd: 13,
        word: "mind",
        mappedWord: "mind",
        wordStartTime: 5.59,
        wordEndTime: 6.03,
      },
      {
        wordStart: 14,
        wordEnd: 14,
        word: "and",
        mappedWord: "and",
        wordStartTime: 6.03,
        wordEndTime: 6.23,
      },
      {
        wordStart: 15,
        wordEnd: 15,
        word: "relax",
        mappedWord: "relax",
        wordStartTime: 6.23,
        wordEndTime: 6.55,
      },
      {
        wordStart: 16,
        wordEnd: 16,
        word: "the",
        mappedWord: "the",
        wordStartTime: 6.55,
        wordEndTime: 6.71,
      },
      {
        wordStart: 17,
        wordEnd: 17,
        word: "body",
        mappedWord: "body",
        wordStartTime: 6.71,
        wordEndTime: 7.31,
      },
      {
        wordStart: 18,
        wordEnd: 18,
        word: "take",
        mappedWord: "take",
        wordStartTime: 8.03,
        wordEndTime: 8.31,
      },
      {
        wordStart: 19,
        wordEnd: 19,
        word: "short",
        mappedWord: "short",
        wordStartTime: 8.31,
        wordEndTime: 8.59,
      },
      {
        wordStart: 20,
        wordEnd: 20,
        word: "breaks",
        mappedWord: "breaks",
        wordStartTime: 8.59,
        wordEndTime: 8.91,
      },
      {
        wordStart: 21,
        wordEnd: 21,
        word: "throughout",
        mappedWord: "throughout",
        wordStartTime: 8.91,
        wordEndTime: 9.31,
      },
      {
        wordStart: 22,
        wordEnd: 22,
        word: "the",
        mappedWord: "the",
        wordStartTime: 9.31,
        wordEndTime: 9.43,
      },
      {
        wordStart: 23,
        wordEnd: 23,
        word: "day",
        mappedWord: "day",
        wordStartTime: 9.43,
        wordEndTime: 9.59,
      },
      {
        wordStart: 24,
        wordEnd: 24,
        word: "to",
        mappedWord: "to",
        wordStartTime: 9.59,
        wordEndTime: 9.71,
      },
      {
        wordStart: 25,
        wordEnd: 25,
        word: "stretch",
        mappedWord: "stretch",
        wordStartTime: 9.71,
        wordEndTime: 10.31,
      },
      {
        wordStart: 26,
        wordEnd: 26,
        word: "walk",
        mappedWord: "walk",
        wordStartTime: 10.31,
        wordEndTime: 10.79,
      },
      {
        wordStart: 27,
        wordEnd: 27,
        word: "or",
        mappedWord: "or",
        wordStartTime: 10.79,
        wordEndTime: 10.99,
      },
      {
        wordStart: 28,
        wordEnd: 28,
        word: "meditate",
        mappedWord: "meditate",
        wordStartTime: 10.99,
        wordEndTime: 11.75,
      },
      {
        wordStart: 29,
        wordEnd: 29,
        word: "engage",
        mappedWord: "engage",
        wordStartTime: 12.63,
        wordEndTime: 13.03,
      },
      {
        wordStart: 30,
        wordEnd: 30,
        word: "in",
        mappedWord: "in",
        wordStartTime: 13.03,
        wordEndTime: 13.15,
      },
      {
        wordStart: 31,
        wordEnd: 31,
        word: "activities",
        mappedWord: "activities",
        wordStartTime: 13.15,
        wordEndTime: 13.75,
      },
      {
        wordStart: 32,
        wordEnd: 32,
        word: "you",
        mappedWord: "you",
        wordStartTime: 13.75,
        wordEndTime: 13.91,
      },
      {
        wordStart: 33,
        wordEnd: 33,
        word: "enjoy",
        mappedWord: "enjoy",
        wordStartTime: 13.91,
        wordEndTime: 14.59,
      },
      {
        wordStart: 34,
        wordEnd: 34,
        word: "such",
        mappedWord: "such",
        wordStartTime: 14.59,
        wordEndTime: 14.91,
      },
      {
        wordStart: 35,
        wordEnd: 35,
        word: "as",
        mappedWord: "as",
        wordStartTime: 14.91,
        wordEndTime: 15.07,
      },
      {
        wordStart: 36,
        wordEnd: 36,
        word: "reading",
        mappedWord: "reading",
        wordStartTime: 15.07,
        wordEndTime: 15.59,
      },
      {
        wordStart: 37,
        wordEnd: 37,
        word: "listening",
        mappedWord: "listening",
        wordStartTime: 15.59,
        wordEndTime: 16.03,
      },
      {
        wordStart: 38,
        wordEnd: 38,
        word: "to",
        mappedWord: "to",
        wordStartTime: 16.03,
        wordEndTime: 16.19,
      },
      {
        wordStart: 39,
        wordEnd: 39,
        word: "music",
        mappedWord: "music",
        wordStartTime: 16.19,
        wordEndTime: 16.75,
      },
      {
        wordStart: 40,
        wordEnd: 40,
        word: "or",
        mappedWord: "or",
        wordStartTime: 16.79,
        wordEndTime: 16.99,
      },
      {
        wordStart: 41,
        wordEnd: 41,
        word: "spending",
        mappedWord: "spending",
        wordStartTime: 16.99,
        wordEndTime: 17.43,
      },
      {
        wordStart: 42,
        wordEnd: 42,
        word: "time",
        mappedWord: "time",
        wordStartTime: 17.43,
        wordEndTime: 17.71,
      },
      {
        wordStart: 43,
        wordEnd: 43,
        word: "with",
        mappedWord: "with",
        wordStartTime: 17.71,
        wordEndTime: 17.87,
      },
      {
        wordStart: 44,
        wordEnd: 44,
        word: "loved",
        mappedWord: "loved",
        wordStartTime: 17.87,
        wordEndTime: 18.11,
      },
      {
        wordStart: 45,
        wordEnd: 45,
        word: "ones",
        mappedWord: "ones",
        wordStartTime: 18.11,
        wordEndTime: 18.75,
      },
      {
        wordStart: 46,
        wordEnd: 46,
        word: "prioritize",
        mappedWord: "prioritize",
        wordStartTime: 19.51,
        wordEndTime: 20.23,
      },
      {
        wordStart: 47,
        wordEnd: 47,
        word: "tasks",
        mappedWord: "tasks",
        wordStartTime: 20.23,
        wordEndTime: 20.91,
      },
      {
        wordStart: 48,
        wordEnd: 48,
        word: "and",
        mappedWord: "and",
        wordStartTime: 20.91,
        wordEndTime: 21.11,
      },
      {
        wordStart: 49,
        wordEnd: 49,
        word: "focus",
        mappedWord: "focus",
        wordStartTime: 21.11,
        wordEndTime: 21.47,
      },
      {
        wordStart: 50,
        wordEnd: 50,
        word: "on",
        mappedWord: "on",
        wordStartTime: 21.47,
        wordEndTime: 21.63,
      },
      {
        wordStart: 51,
        wordEnd: 51,
        word: "one",
        mappedWord: "one",
        wordStartTime: 21.63,
        wordEndTime: 21.83,
      },
      {
        wordStart: 52,
        wordEnd: 52,
        word: "thing",
        mappedWord: "thing",
        wordStartTime: 21.83,
        wordEndTime: 22.07,
      },
      {
        wordStart: 53,
        wordEnd: 53,
        word: "at",
        mappedWord: "at",
        wordStartTime: 22.07,
        wordEndTime: 22.19,
      },
      {
        wordStart: 54,
        wordEnd: 54,
        word: "a",
        mappedWord: "a",
        wordStartTime: 22.19,
        wordEndTime: 22.23,
      },
      {
        wordStart: 55,
        wordEnd: 55,
        word: "time",
        mappedWord: "time",
        wordStartTime: 22.23,
        wordEndTime: 22.63,
      },
      {
        wordStart: 56,
        wordEnd: 56,
        word: "to",
        mappedWord: "to",
        wordStartTime: 22.63,
        wordEndTime: 22.83,
      },
      {
        wordStart: 57,
        wordEnd: 57,
        word: "avoid",
        mappedWord: "avoid",
        wordStartTime: 22.83,
        wordEndTime: 23.11,
      },
      {
        wordStart: 58,
        wordEnd: 58,
        word: "feeling",
        mappedWord: "feeling",
        wordStartTime: 23.11,
        wordEndTime: 23.47,
      },
      {
        wordStart: 59,
        wordEnd: 59,
        word: "overwhelmed",
        mappedWord: "overwhelmed",
        wordStartTime: 23.47,
        wordEndTime: 24.27,
      },
      {
        wordStart: 60,
        wordEnd: 60,
        word: "set",
        mappedWord: "set",
        wordStartTime: 25.03,
        wordEndTime: 25.35,
      },
      {
        wordStart: 61,
        wordEnd: 61,
        word: "boundaries",
        mappedWord: "boundaries",
        wordStartTime: 25.35,
        wordEndTime: 25.87,
      },
      {
        wordStart: 62,
        wordEnd: 62,
        word: "and",
        mappedWord: "and",
        wordStartTime: 25.87,
        wordEndTime: 26.03,
      },
      {
        wordStart: 63,
        wordEnd: 63,
        word: "learn",
        mappedWord: "learn",
        wordStartTime: 26.03,
        wordEndTime: 26.23,
      },
      {
        wordStart: 64,
        wordEnd: 64,
        word: "to",
        mappedWord: "to",
        wordStartTime: 26.23,
        wordEndTime: 26.35,
      },
      {
        wordStart: 65,
        wordEnd: 65,
        word: "say",
        mappedWord: "say",
        wordStartTime: 26.35,
        wordEndTime: 26.55,
      },
      {
        wordStart: 66,
        wordEnd: 66,
        word: "no",
        mappedWord: "no",
        wordStartTime: 26.55,
        wordEndTime: 26.79,
      },
      {
        wordStart: 67,
        wordEnd: 67,
        word: "to",
        mappedWord: "to",
        wordStartTime: 26.79,
        wordEndTime: 26.95,
      },
      {
        wordStart: 68,
        wordEnd: 68,
        word: "excessive",
        mappedWord: "excessive",
        wordStartTime: 26.95,
        wordEndTime: 27.39,
      },
      {
        wordStart: 69,
        wordEnd: 69,
        word: "demands",
        mappedWord: "demands",
        wordStartTime: 27.39,
        wordEndTime: 27.91,
      },
      {
        wordStart: 70,
        wordEnd: 70,
        word: "on",
        mappedWord: "on",
        wordStartTime: 27.91,
        wordEndTime: 28.07,
      },
      {
        wordStart: 71,
        wordEnd: 71,
        word: "your",
        mappedWord: "your",
        wordStartTime: 28.07,
        wordEndTime: 28.23,
      },
      {
        wordStart: 72,
        wordEnd: 72,
        word: "time",
        mappedWord: "time",
        wordStartTime: 28.23,
        wordEndTime: 28.59,
      },
      {
        wordStart: 73,
        wordEnd: 73,
        word: "and",
        mappedWord: "and",
        wordStartTime: 28.59,
        wordEndTime: 28.83,
      },
      {
        wordStart: 74,
        wordEnd: 74,
        word: "energy",
        mappedWord: "energy",
        wordStartTime: 28.83,
        wordEndTime: 29.51,
      },
      {
        wordStart: 75,
        wordEnd: 75,
        word: "get",
        mappedWord: "get",
        wordStartTime: 30.27,
        wordEndTime: 30.43,
      },
      {
        wordStart: 76,
        wordEnd: 76,
        word: "regular",
        mappedWord: "regular",
        wordStartTime: 30.43,
        wordEndTime: 30.91,
      },
      {
        wordStart: 77,
        wordEnd: 77,
        word: "exercise",
        mappedWord: "exercise",
        wordStartTime: 30.91,
        wordEndTime: 31.59,
      },
      {
        wordStart: 78,
        wordEnd: 78,
        word: "to",
        mappedWord: "to",
        wordStartTime: 31.59,
        wordEndTime: 31.71,
      },
      {
        wordStart: 79,
        wordEnd: 79,
        word: "release",
        mappedWord: "release",
        wordStartTime: 31.71,
        wordEndTime: 32.07,
      },
      {
        wordStart: 80,
        wordEnd: 80,
        word: "tension",
        mappedWord: "tension",
        wordStartTime: 32.07,
        wordEndTime: 32.67,
      },
      {
        wordStart: 81,
        wordEnd: 81,
        word: "and",
        mappedWord: "and",
        wordStartTime: 32.67,
        wordEndTime: 32.87,
      },
      {
        wordStart: 82,
        wordEnd: 82,
        word: "boost",
        mappedWord: "boost",
        wordStartTime: 32.87,
        wordEndTime: 33.19,
      },
      {
        wordStart: 83,
        wordEnd: 83,
        word: "moodboosting",
        mappedWord: "mood",
        wordStartTime: 33.19,
        wordEndTime: 33.39,
      },
      {
        wordStart: 85,
        wordEnd: 85,
        word: "endorphins",
        mappedWord: "endorphins",
        wordStartTime: 33.79,
        wordEndTime: 34.67,
      },
      {
        wordStart: 86,
        wordEnd: 86,
        word: "get",
        mappedWord: "get",
        wordStartTime: 35.47,
        wordEndTime: 35.67,
      },
      {
        wordStart: 87,
        wordEnd: 87,
        word: "enough",
        mappedWord: "enough",
        wordStartTime: 35.67,
        wordEndTime: 35.91,
      },
      {
        wordStart: 88,
        wordEnd: 88,
        word: "sleep",
        mappedWord: "sleep",
        wordStartTime: 35.91,
        wordEndTime: 36.31,
      },
      {
        wordStart: 89,
        wordEnd: 89,
        word: "and",
        mappedWord: "and",
        wordStartTime: 36.31,
        wordEndTime: 36.51,
      },
      {
        wordStart: 90,
        wordEnd: 90,
        word: "maintain",
        mappedWord: "maintain",
        wordStartTime: 36.51,
        wordEndTime: 36.91,
      },
      {
        wordStart: 91,
        wordEnd: 91,
        word: "a",
        mappedWord: "a",
        wordStartTime: 36.91,
        wordEndTime: 36.95,
      },
      {
        wordStart: 92,
        wordEnd: 92,
        word: "healthy",
        mappedWord: "healthy",
        wordStartTime: 36.95,
        wordEndTime: 37.35,
      },
      {
        wordStart: 93,
        wordEnd: 93,
        word: "lifestyle",
        mappedWord: "lifestyle",
        wordStartTime: 37.35,
        wordEndTime: 37.95,
      },
      {
        wordStart: 94,
        wordEnd: 94,
        word: "to",
        mappedWord: "to",
        wordStartTime: 37.95,
        wordEndTime: 38.11,
      },
      {
        wordStart: 95,
        wordEnd: 95,
        word: "support",
        mappedWord: "support",
        wordStartTime: 38.11,
        wordEndTime: 38.51,
      },
      {
        wordStart: 96,
        wordEnd: 96,
        word: "stress",
        mappedWord: "stress",
        wordStartTime: 38.51,
        wordEndTime: 38.83,
      },
      {
        wordStart: 97,
        wordEnd: 97,
        word: "resilience",
        mappedWord: "resilience",
        wordStartTime: 38.83,
        wordEndTime: 39.47,
      },
    ],
  },
  message: "Request successfully executed!",
};

const data3 = [
  {
    id: 0,
    type: "video",
    start: 0,
    end: 3.4,
    info: {
      url: "https://res.cloudinary.com/dlmsxlf2n/video/upload/v1717684301/Diveo/Assets/videos/stock-vid3.mp4",
      height: 720,
      width: 1280,
    },
  },
  {
    id: 1,
    type: "video",
    start: 3.4,
    end: 8.3,
    info: {
      url: "https://res.cloudinary.com/dlmsxlf2n/video/upload/v1717684301/Diveo/Assets/videos/stock-vid2.mp4",
      height: 720,
      width: 1365,
    },
  },
  {
    id: 2,
    type: "video",
    start: 8.3,
    end: 13.7,
    info: {
      url: "https://res.cloudinary.com/dlmsxlf2n/video/upload/v1717684301/Diveo/Assets/videos/stock-vid3.mp4",
      height: 720,
      width: 1280,
    },
  },
  {
    id: 3,
    type: "video",
    start: 13.7,
    end: 19,
    info: {
      url: "https://res.cloudinary.com/dlmsxlf2n/video/upload/v1717684301/Diveo/Assets/videos/stock-vid2.mp4",
      height: 720,
      width: 1365,
    },
  },
  {
    id: 4,
    type: "video",
    start: 0,
    end: 3.4,
    info: {
      url: "https://res.cloudinary.com/dlmsxlf2n/video/upload/v1717684301/Diveo/Assets/videos/stock-vid3.mp4",
      height: 720,
      width: 1280,
    },
  },
  {
    id: 5,
    type: "video",
    start: 3.4,
    end: 8.3,
    info: {
      url: "https://res.cloudinary.com/dlmsxlf2n/video/upload/v1717684301/Diveo/Assets/videos/stock-vid2.mp4",
      height: 720,
      width: 1365,
    },
  },
  {
    id: 6,
    type: "video",
    start: 8.3,
    end: 13.7,
    info: {
      url: "https://res.cloudinary.com/dlmsxlf2n/video/upload/v1717684301/Diveo/Assets/videos/stock-vid3.mp4",
      height: 720,
      width: 1280,
    },
  },
  {
    id: 7,
    type: "video",
    start: 13.7,
    end: 19,
    info: {
      url: "https://res.cloudinary.com/dlmsxlf2n/video/upload/v1717684301/Diveo/Assets/videos/stock-vid2.mp4",
      height: 720,
      width: 1365,
    },
  },
];

const authUser = {
  uid: "BiUwPrxsUnSlSO2XNO0bAkme2ih2",
  email: "ashwynbhoyar10@gmail.com",
  emailVerified: true,
  displayName: "Ashwyn Bhoyar",
  isAnonymous: false,
  photoURL:
    "https://lh3.googleusercontent.com/a/AAcHTtcz8MphAJabU0fMVnL7PdcNRJIiecMll8oukLWjSRyT=s96-c",
  providerData: [
    {
      providerId: "google.com",
      uid: "113942457964060973467",
      displayName: "Ashwyn Bhoyar",
      email: "ashwynbhoyar10@gmail.com",
      phoneNumber: null,
      photoURL:
        "https://lh3.googleusercontent.com/a/ACg8ocKm_aqyunVsLH9tLEPtbyzJGf-A3n_lyOXVBYblIjR4nU18lw=s96-c",
    },
    {
      providerId: "password",
      uid: "ashwynbhoyar10@gmail.com",
      displayName: "Ashwyn Bhoyar",
      email: "ashwynbhoyar10@gmail.com",
      phoneNumber: null,
      photoURL:
        "https://lh3.googleusercontent.com/a/AAcHTtcz8MphAJabU0fMVnL7PdcNRJIiecMll8oukLWjSRyT=s96-c",
    },
  ],
  stsTokenManager: {
    refreshToken:
      "AMf-vBw995l6xkq8-PidNaSTSPZOmpp9BjFB24vA06Vrvj3npLqbDscfTZyfMdp2sq-e2hmQy6rVzcxjayTp31uwDWKTNft4GNP65PY5iYfuGQe3KrdTwRiw27ulJuK5VADGvi6F9yugR1MXrqgEoPNBi39LynYcq9xRLrVF0kg9hmgiUtLTWGS4dWc9TBfpHSXXOh4z2Z_FBsaqA_po16DlvlfhGRMZZhBX9RZkIjtN6PoQoACtEZEp6HvQUub7YuwUr0b8XkT-33qAZTvI_GWuCokJdEmsf577Vee3bh-6hTiY3V7S6s85cHFwrn7z2ca50AvTt59t3GgIf20SECmArT8bLGpLpQ9MF06pnFYcEmkEkBDyyTF2Wo2tuKmsqehLQtIJs_x-T3sJdgidsu6ft6FZ0k6vwbER68l41M11pOPNN0vXX2s",
    accessToken:
      "eyJhbGciOiJSUzI1NiIsImtpZCI6ImYwOGU2ZTNmNzg4ZDYwMTk0MDA1ZGJiYzE5NDc0YmY5Mjg5ZDM5ZWEiLCJ0eXAiOiJKV1QifQ.eyJuYW1lIjoiQXNod3luIEJob3lhciIsInBpY3R1cmUiOiJodHRwczovL2xoMy5nb29nbGV1c2VyY29udGVudC5jb20vYS9BQWNIVHRjejhNcGhBSmFiVTBmTVZuTDdQZGNOUkpJaWVjTWxsOG91a0xXalNSeVQ9czk2LWMiLCJzdHJpcGVSb2xlIjoicHJlbWl1bSIsImlzcyI6Imh0dHBzOi8vc2VjdXJldG9rZW4uZ29vZ2xlLmNvbS9kaXZlby1hNjczNSIsImF1ZCI6ImRpdmVvLWE2NzM1IiwiYXV0aF90aW1lIjoxNzEzMzY4MzQyLCJ1c2VyX2lkIjoiQmlVd1ByeHNVblNsU08yWE5PMGJBa21lMmloMiIsInN1YiI6IkJpVXdQcnhzVW5TbFNPMlhOTzBiQWttZTJpaDIiLCJpYXQiOjE3MTk2MDI0MDUsImV4cCI6MTcxOTYwNjAwNSwiZW1haWwiOiJhc2h3eW5iaG95YXIxMEBnbWFpbC5jb20iLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZSwiZmlyZWJhc2UiOnsiaWRlbnRpdGllcyI6eyJnb29nbGUuY29tIjpbIjExMzk0MjQ1Nzk2NDA2MDk3MzQ2NyJdLCJlbWFpbCI6WyJhc2h3eW5iaG95YXIxMEBnbWFpbC5jb20iXX0sInNpZ25faW5fcHJvdmlkZXIiOiJnb29nbGUuY29tIn19.OrJt4nS7LEv0N1Dnq1_WYUfnx_Caw0Ro23Yz-oHNUnHjyvY6Hh5gGD0lbUz1Un1xqFjdwxMd5eKvdDimXSuGYNHeVN4_22CzZCkzZq7JxD7HODUuxnGrjeG9EmPVlPJLnvxihF0nO8HWsKgymGTZCXAjhkzaqJpKhaZJosBdDJ_cOlCr4DbA2q9Ga0JCvSVXsIee72hVo6n2ehymJaMey3SPiyxesDGsmerM3gRbqR_pOBRMfXoaHEtMOdavo04RGzY6B6CA0FUHbeRWAZi85GHnsJ-kdFzRSHXNkmFL54yk6aGBxn1ZAauyiiKZOI4eOE8eZQSBCUUIyYwVSXZoYw",
    expirationTime: 1719606006022,
  },
  createdAt: "1694009473287",
  lastLoginAt: "1713368342246",
  apiKey: "AIzaSyBAtW9V6J7EUS2Z8yNbqPOqY0txe6g-9Hw",
  appName: "[DEFAULT]",
};

const data1 = {
  data: {
    sentenceRangeMap: [
      {
        start: 0,
        end: 5,
        startTime: 0.15,
        endTime: 2.15,
      },
      {
        start: 6,
        end: 17,
        startTime: 2.91,
        endTime: 7.31,
      },
      {
        start: 18,
        end: 28,
        startTime: 8.03,
        endTime: 11.75,
      },
      {
        start: 29,
        end: 45,
        startTime: 12.63,
        endTime: 18.75,
      },
      {
        start: 46,
        end: 59,
        startTime: 19.51,
        endTime: 24.27,
      },
      {
        start: 60,
        end: 74,
        startTime: 25.03,
        endTime: 29.51,
      },
      {
        start: 75,
        end: 85,
        startTime: 30.27,
        endTime: 34.35,
      },
      {
        start: 86,
        end: 97,
        startTime: 35.32,
        endTime: 39.32,
      },
    ],
    wordLevelTimestamps: [
      {
        wordStart: 0,
        wordEnd: 0,
        word: "5",
        mappedWord: "five",
        wordStartTime: 0.15,
        wordEndTime: 0.43,
      },
      {
        wordStart: 1,
        wordEnd: 1,
        word: "simple",
        mappedWord: "simple",
        wordStartTime: 0.43,
        wordEndTime: 0.75,
      },
      {
        wordStart: 2,
        wordEnd: 2,
        word: "ways",
        mappedWord: "ways",
        wordStartTime: 0.75,
        wordEndTime: 1.03,
      },
      {
        wordStart: 3,
        wordEnd: 3,
        word: "to",
        mappedWord: "to",
        wordStartTime: 1.03,
        wordEndTime: 1.15,
      },
      {
        wordStart: 4,
        wordEnd: 4,
        word: "reduce",
        mappedWord: "reduce",
        wordStartTime: 1.15,
        wordEndTime: 1.51,
      },
      {
        wordStart: 5,
        wordEnd: 5,
        word: "stress",
        mappedWord: "stress",
        wordStartTime: 1.51,
        wordEndTime: 2.15,
      },
      {
        wordStart: 6,
        wordEnd: 6,
        word: "practice",
        mappedWord: "practice",
        wordStartTime: 2.91,
        wordEndTime: 3.43,
      },
      {
        wordStart: 7,
        wordEnd: 7,
        word: "deep",
        mappedWord: "deep",
        wordStartTime: 3.43,
        wordEndTime: 3.67,
      },
      {
        wordStart: 8,
        wordEnd: 8,
        word: "breathing",
        mappedWord: "breathing",
        wordStartTime: 3.67,
        wordEndTime: 4.11,
      },
      {
        wordStart: 9,
        wordEnd: 9,
        word: "exercises",
        mappedWord: "exercises",
        wordStartTime: 4.11,
        wordEndTime: 5.07,
      },
      {
        wordStart: 10,
        wordEnd: 10,
        word: "to",
        mappedWord: "to",
        wordStartTime: 5.07,
        wordEndTime: 5.23,
      },
      {
        wordStart: 11,
        wordEnd: 11,
        word: "calm",
        mappedWord: "calm",
        wordStartTime: 5.23,
        wordEndTime: 5.47,
      },
      {
        wordStart: 12,
        wordEnd: 12,
        word: "the",
        mappedWord: "the",
        wordStartTime: 5.47,
        wordEndTime: 5.59,
      },
      {
        wordStart: 13,
        wordEnd: 13,
        word: "mind",
        mappedWord: "mind",
        wordStartTime: 5.59,
        wordEndTime: 6.03,
      },
      {
        wordStart: 14,
        wordEnd: 14,
        word: "and",
        mappedWord: "and",
        wordStartTime: 6.03,
        wordEndTime: 6.23,
      },
      {
        wordStart: 15,
        wordEnd: 15,
        word: "relax",
        mappedWord: "relax",
        wordStartTime: 6.23,
        wordEndTime: 6.55,
      },
      {
        wordStart: 16,
        wordEnd: 16,
        word: "the",
        mappedWord: "the",
        wordStartTime: 6.55,
        wordEndTime: 6.71,
      },
      {
        wordStart: 17,
        wordEnd: 17,
        word: "body",
        mappedWord: "body",
        wordStartTime: 6.71,
        wordEndTime: 7.31,
      },
      {
        wordStart: 18,
        wordEnd: 18,
        word: "take",
        mappedWord: "take",
        wordStartTime: 8.03,
        wordEndTime: 8.31,
      },
      {
        wordStart: 19,
        wordEnd: 19,
        word: "short",
        mappedWord: "short",
        wordStartTime: 8.31,
        wordEndTime: 8.59,
      },
      {
        wordStart: 20,
        wordEnd: 20,
        word: "breaks",
        mappedWord: "breaks",
        wordStartTime: 8.59,
        wordEndTime: 8.91,
      },
      {
        wordStart: 21,
        wordEnd: 21,
        word: "throughout",
        mappedWord: "throughout",
        wordStartTime: 8.91,
        wordEndTime: 9.31,
      },
      {
        wordStart: 22,
        wordEnd: 22,
        word: "the",
        mappedWord: "the",
        wordStartTime: 9.31,
        wordEndTime: 9.43,
      },
      {
        wordStart: 23,
        wordEnd: 23,
        word: "day",
        mappedWord: "day",
        wordStartTime: 9.43,
        wordEndTime: 9.59,
      },
      {
        wordStart: 24,
        wordEnd: 24,
        word: "to",
        mappedWord: "to",
        wordStartTime: 9.59,
        wordEndTime: 9.71,
      },
      {
        wordStart: 25,
        wordEnd: 25,
        word: "stretch",
        mappedWord: "stretch",
        wordStartTime: 9.71,
        wordEndTime: 10.31,
      },
      {
        wordStart: 26,
        wordEnd: 26,
        word: "walk",
        mappedWord: "walk",
        wordStartTime: 10.31,
        wordEndTime: 10.79,
      },
      {
        wordStart: 27,
        wordEnd: 27,
        word: "or",
        mappedWord: "or",
        wordStartTime: 10.79,
        wordEndTime: 10.99,
      },
      {
        wordStart: 28,
        wordEnd: 28,
        word: "meditate",
        mappedWord: "meditate",
        wordStartTime: 10.99,
        wordEndTime: 11.75,
      },
      {
        wordStart: 29,
        wordEnd: 29,
        word: "engage",
        mappedWord: "engage",
        wordStartTime: 12.63,
        wordEndTime: 13.03,
      },
      {
        wordStart: 30,
        wordEnd: 30,
        word: "in",
        mappedWord: "in",
        wordStartTime: 13.03,
        wordEndTime: 13.15,
      },
      {
        wordStart: 31,
        wordEnd: 31,
        word: "activities",
        mappedWord: "activities",
        wordStartTime: 13.15,
        wordEndTime: 13.75,
      },
      {
        wordStart: 32,
        wordEnd: 32,
        word: "you",
        mappedWord: "you",
        wordStartTime: 13.75,
        wordEndTime: 13.91,
      },
      {
        wordStart: 33,
        wordEnd: 33,
        word: "enjoy",
        mappedWord: "enjoy",
        wordStartTime: 13.91,
        wordEndTime: 14.59,
      },
      {
        wordStart: 34,
        wordEnd: 34,
        word: "such",
        mappedWord: "such",
        wordStartTime: 14.59,
        wordEndTime: 14.91,
      },
      {
        wordStart: 35,
        wordEnd: 35,
        word: "as",
        mappedWord: "as",
        wordStartTime: 14.91,
        wordEndTime: 15.07,
      },
      {
        wordStart: 36,
        wordEnd: 36,
        word: "reading",
        mappedWord: "reading",
        wordStartTime: 15.07,
        wordEndTime: 15.59,
      },
      {
        wordStart: 37,
        wordEnd: 37,
        word: "listening",
        mappedWord: "listening",
        wordStartTime: 15.59,
        wordEndTime: 16.03,
      },
      {
        wordStart: 38,
        wordEnd: 38,
        word: "to",
        mappedWord: "to",
        wordStartTime: 16.03,
        wordEndTime: 16.19,
      },
      {
        wordStart: 39,
        wordEnd: 39,
        word: "music",
        mappedWord: "music",
        wordStartTime: 16.19,
        wordEndTime: 16.75,
      },
      {
        wordStart: 40,
        wordEnd: 40,
        word: "or",
        mappedWord: "or",
        wordStartTime: 16.79,
        wordEndTime: 16.99,
      },
      {
        wordStart: 41,
        wordEnd: 41,
        word: "spending",
        mappedWord: "spending",
        wordStartTime: 16.99,
        wordEndTime: 17.43,
      },
      {
        wordStart: 42,
        wordEnd: 42,
        word: "time",
        mappedWord: "time",
        wordStartTime: 17.43,
        wordEndTime: 17.71,
      },
      {
        wordStart: 43,
        wordEnd: 43,
        word: "with",
        mappedWord: "with",
        wordStartTime: 17.71,
        wordEndTime: 17.87,
      },
      {
        wordStart: 44,
        wordEnd: 44,
        word: "loved",
        mappedWord: "loved",
        wordStartTime: 17.87,
        wordEndTime: 18.11,
      },
      {
        wordStart: 45,
        wordEnd: 45,
        word: "ones",
        mappedWord: "ones",
        wordStartTime: 18.11,
        wordEndTime: 18.75,
      },
      {
        wordStart: 46,
        wordEnd: 46,
        word: "prioritize",
        mappedWord: "prioritize",
        wordStartTime: 19.51,
        wordEndTime: 20.23,
      },
      {
        wordStart: 47,
        wordEnd: 47,
        word: "tasks",
        mappedWord: "tasks",
        wordStartTime: 20.23,
        wordEndTime: 20.91,
      },
      {
        wordStart: 48,
        wordEnd: 48,
        word: "and",
        mappedWord: "and",
        wordStartTime: 20.91,
        wordEndTime: 21.11,
      },
      {
        wordStart: 49,
        wordEnd: 49,
        word: "focus",
        mappedWord: "focus",
        wordStartTime: 21.11,
        wordEndTime: 21.47,
      },
      {
        wordStart: 50,
        wordEnd: 50,
        word: "on",
        mappedWord: "on",
        wordStartTime: 21.47,
        wordEndTime: 21.63,
      },
      {
        wordStart: 51,
        wordEnd: 51,
        word: "one",
        mappedWord: "one",
        wordStartTime: 21.63,
        wordEndTime: 21.83,
      },
      {
        wordStart: 52,
        wordEnd: 52,
        word: "thing",
        mappedWord: "thing",
        wordStartTime: 21.83,
        wordEndTime: 22.07,
      },
      {
        wordStart: 53,
        wordEnd: 53,
        word: "at",
        mappedWord: "at",
        wordStartTime: 22.07,
        wordEndTime: 22.19,
      },
      {
        wordStart: 54,
        wordEnd: 54,
        word: "a",
        mappedWord: "a",
        wordStartTime: 22.19,
        wordEndTime: 22.23,
      },
      {
        wordStart: 55,
        wordEnd: 55,
        word: "time",
        mappedWord: "time",
        wordStartTime: 22.23,
        wordEndTime: 22.63,
      },
      {
        wordStart: 56,
        wordEnd: 56,
        word: "to",
        mappedWord: "to",
        wordStartTime: 22.63,
        wordEndTime: 22.83,
      },
      {
        wordStart: 57,
        wordEnd: 57,
        word: "avoid",
        mappedWord: "avoid",
        wordStartTime: 22.83,
        wordEndTime: 23.11,
      },
      {
        wordStart: 58,
        wordEnd: 58,
        word: "feeling",
        mappedWord: "feeling",
        wordStartTime: 23.11,
        wordEndTime: 23.47,
      },
      {
        wordStart: 59,
        wordEnd: 59,
        word: "overwhelmed",
        mappedWord: "overwhelmed",
        wordStartTime: 23.47,
        wordEndTime: 24.27,
      },
      {
        wordStart: 60,
        wordEnd: 60,
        word: "set",
        mappedWord: "set",
        wordStartTime: 25.03,
        wordEndTime: 25.35,
      },
      {
        wordStart: 61,
        wordEnd: 61,
        word: "boundaries",
        mappedWord: "boundaries",
        wordStartTime: 25.35,
        wordEndTime: 25.87,
      },
      {
        wordStart: 62,
        wordEnd: 62,
        word: "and",
        mappedWord: "and",
        wordStartTime: 25.87,
        wordEndTime: 26.03,
      },
      {
        wordStart: 63,
        wordEnd: 63,
        word: "learn",
        mappedWord: "learn",
        wordStartTime: 26.03,
        wordEndTime: 26.23,
      },
      {
        wordStart: 64,
        wordEnd: 64,
        word: "to",
        mappedWord: "to",
        wordStartTime: 26.23,
        wordEndTime: 26.35,
      },
      {
        wordStart: 65,
        wordEnd: 65,
        word: "say",
        mappedWord: "say",
        wordStartTime: 26.35,
        wordEndTime: 26.55,
      },
      {
        wordStart: 66,
        wordEnd: 66,
        word: "no",
        mappedWord: "no",
        wordStartTime: 26.55,
        wordEndTime: 26.79,
      },
      {
        wordStart: 67,
        wordEnd: 67,
        word: "to",
        mappedWord: "to",
        wordStartTime: 26.79,
        wordEndTime: 26.95,
      },
      {
        wordStart: 68,
        wordEnd: 68,
        word: "excessive",
        mappedWord: "excessive",
        wordStartTime: 26.95,
        wordEndTime: 27.39,
      },
      {
        wordStart: 69,
        wordEnd: 69,
        word: "demands",
        mappedWord: "demands",
        wordStartTime: 27.39,
        wordEndTime: 27.91,
      },
      {
        wordStart: 70,
        wordEnd: 70,
        word: "on",
        mappedWord: "on",
        wordStartTime: 27.91,
        wordEndTime: 28.07,
      },
      {
        wordStart: 71,
        wordEnd: 71,
        word: "your",
        mappedWord: "your",
        wordStartTime: 28.07,
        wordEndTime: 28.23,
      },
      {
        wordStart: 72,
        wordEnd: 72,
        word: "time",
        mappedWord: "time",
        wordStartTime: 28.23,
        wordEndTime: 28.59,
      },
      {
        wordStart: 73,
        wordEnd: 73,
        word: "and",
        mappedWord: "and",
        wordStartTime: 28.59,
        wordEndTime: 28.83,
      },
      {
        wordStart: 74,
        wordEnd: 74,
        word: "energy",
        mappedWord: "energy",
        wordStartTime: 28.83,
        wordEndTime: 29.51,
      },
      {
        wordStart: 75,
        wordEnd: 75,
        word: "get",
        mappedWord: "get",
        wordStartTime: 30.27,
        wordEndTime: 30.43,
      },
      {
        wordStart: 76,
        wordEnd: 76,
        word: "regular",
        mappedWord: "regular",
        wordStartTime: 30.43,
        wordEndTime: 30.91,
      },
      {
        wordStart: 77,
        wordEnd: 77,
        word: "exercise",
        mappedWord: "exercise",
        wordStartTime: 30.91,
        wordEndTime: 31.59,
      },
      {
        wordStart: 78,
        wordEnd: 78,
        word: "to",
        mappedWord: "to",
        wordStartTime: 31.59,
        wordEndTime: 31.71,
      },
      {
        wordStart: 79,
        wordEnd: 79,
        word: "release",
        mappedWord: "release",
        wordStartTime: 31.71,
        wordEndTime: 32.07,
      },
      {
        wordStart: 80,
        wordEnd: 80,
        word: "tension",
        mappedWord: "tension",
        wordStartTime: 32.07,
        wordEndTime: 32.67,
      },
      {
        wordStart: 81,
        wordEnd: 81,
        word: "and",
        mappedWord: "and",
        wordStartTime: 32.67,
        wordEndTime: 32.87,
      },
      {
        wordStart: 82,
        wordEnd: 82,
        word: "boost",
        mappedWord: "boost",
        wordStartTime: 32.87,
        wordEndTime: 33.19,
      },
      {
        wordStart: 83,
        wordEnd: 83,
        word: "moodboosting",
        mappedWord: "mood",
        wordStartTime: 33.19,
        wordEndTime: 33.39,
      },
      {
        wordStart: 85,
        wordEnd: 85,
        word: "endorphins",
        mappedWord: "endorphins",
        wordStartTime: 33.79,
        wordEndTime: 34.35,
      },
      {
        wordStart: 86,
        wordEnd: 86,
        word: "get",
        mappedWord: "get",
        wordStartTime: 35.32,
        wordEndTime: 35.52,
      },
      {
        wordStart: 87,
        wordEnd: 87,
        word: "enough",
        mappedWord: "enough",
        wordStartTime: 35.52,
        wordEndTime: 35.76,
      },
      {
        wordStart: 88,
        wordEnd: 88,
        word: "sleep",
        mappedWord: "sleep",
        wordStartTime: 35.76,
        wordEndTime: 36.16,
      },
      {
        wordStart: 89,
        wordEnd: 89,
        word: "and",
        mappedWord: "and",
        wordStartTime: 36.16,
        wordEndTime: 36.36,
      },
      {
        wordStart: 90,
        wordEnd: 90,
        word: "maintain",
        mappedWord: "maintain",
        wordStartTime: 36.36,
        wordEndTime: 36.76,
      },
      {
        wordStart: 91,
        wordEnd: 91,
        word: "a",
        mappedWord: "a",
        wordStartTime: 36.76,
        wordEndTime: 36.8,
      },
      {
        wordStart: 92,
        wordEnd: 92,
        word: "healthy",
        mappedWord: "healthy",
        wordStartTime: 36.8,
        wordEndTime: 37.2,
      },
      {
        wordStart: 93,
        wordEnd: 93,
        word: "lifestyle",
        mappedWord: "lifestyle",
        wordStartTime: 37.2,
        wordEndTime: 37.8,
      },
      {
        wordStart: 94,
        wordEnd: 94,
        word: "to",
        mappedWord: "to",
        wordStartTime: 37.8,
        wordEndTime: 37.96,
      },
      {
        wordStart: 95,
        wordEnd: 95,
        word: "support",
        mappedWord: "support",
        wordStartTime: 37.96,
        wordEndTime: 38.36,
      },
      {
        wordStart: 96,
        wordEnd: 96,
        word: "stress",
        mappedWord: "stress",
        wordStartTime: 38.36,
        wordEndTime: 38.68,
      },
      {
        wordStart: 97,
        wordEnd: 97,
        word: "resilience",
        mappedWord: "resilience",
        wordStartTime: 38.68,
        wordEndTime: 39.32,
      },
    ],
    audioDuration: 40.4125,
  },
  message: "Request successfully executed!",
};

export { data1, data2, data3, authUser };
