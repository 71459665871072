import * as StepFunctions from "aws-sdk/clients/stepfunctions";
import * as AWS from "aws-sdk";

AWS.config.update({
  region: "ap-northeast-1",
  accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
});

const stepFunctions = new StepFunctions({
  region: "ap-northeast-1",
});

export const getExecutionStatus = (jobId) => {
  return new Promise((resolve, reject) => {
    const params = {
      executionArn: `arn:aws:states:ap-northeast-1:120031339786:execution:Video-Generator:Video-Generator-${jobId}`,
    };

    stepFunctions.describeExecution(params, function (err, data) {
      if (err) {
        console.log(err, err.stack);
        reject(err);
      }
      // an error occurred
      else {
        console.log(data); // successful response
        resolve(data);
      }
    });
  });
};
