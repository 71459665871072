import React, {useState, useEffect} from 'react'
import '../css/editor.css';
import { useDispatch, useSelector } from 'react-redux'
import { setActiveSceneIndex, setAlertDialogInfo } from '../redux/storySlice';
import reactCSS from 'reactcss'

const StoryOptions = () => {
    const dispatch = useDispatch()

    const [scenes, setScenes] = useState([])
    const [script, setScript] = useState('')
    const [activeMenu, setActiveMenu] = useState(0)
    
    const sceneInfoArray = useSelector(
        (state) => state.storyReducer.sceneInfoArray
    )

    const audioInfo = useSelector(
        (state) => state.storyReducer.audioInfo
    )

    const activeSceneIndex = useSelector(
        (state) => state.storyReducer.activeSceneIndex
    )

    useEffect(() => {
        if(!scenes.length){
            setScenes(sceneInfoArray)
        }
        setScript(audioInfo.text)
        
    }, [scenes])

    const changeActiveScene = (sceneIndex) => {
        dispatch(setActiveSceneIndex({index: sceneIndex}))
    }

    const handleScriptChange = (e) => {
        const text = e.target.value
        console.log('Script Changed: ', text)
        setScript(text)
    }

    const updateScript = () => {
        dispatch(setAlertDialogInfo({info: {isOpen: true, source: 'story-options'}}))
    }

    const styles = reactCSS({
        'default': {
            menuItem: {
                display: 'inline-block',
                background: '#eee',
                textAlign: 'center',
                borderRadius: 10,
                padding: 5,
                paddingLeft: 20,
                paddingRight: 20,
                cursor: 'pointer',
            },
            selectedMenuItem: {
                display: 'inline-block',
                background: '#fff',
                borderRadius: 10,
                textAlign: 'center',
                padding: 5,
                paddingInLine: 15,
                paddingLeft: 20,
                paddingRight: 20,
                cursor: 'pointer',
            }      
        },
      });
      

    return (
        <div style={{height: '100%'}}>
            
            <div style={{fontSize: 18,  fontWeight: 700, margin: 'auto', padding: 20, color: '#424242'}}>Scenes </div>

            {/* <div style={{ border: '1px solid #eee', borderRadius: 10, padding: 4, width: 178, margin: 'auto', backgroundColor: '#eee', marginTop: 20}}>
                <div style={activeMenu == 0 ? styles.selectedMenuItem : styles.menuItem} onClick={() => setActiveMenu(0)}>
                    Scenes
                </div>
                <div style={activeMenu == 1 ? styles.selectedMenuItem : styles.menuItem} onClick={() => setActiveMenu(1)}>
                    Script
                </div>
            </div> */}

            {
                activeMenu == 0 ?
                    <div style={{overflow: 'scroll', height: '95%', overflow: 'scroll'}}>
                    {
                        scenes.map((scene, index) => 
                            <div style={{padding: 8, border: activeSceneIndex == index ? '3px solid #5290D6': '1px solid #eee', borderRadius: 5, width: '90%', cursor: 'pointer', margin: 'auto', marginTop: 10 }} onClick={() => changeActiveScene(index)}>
                                <div style={{fontSize: 13, color: activeSceneIndex == index ? '#5290D6' : '#000', fontWeight: 700, marginBottom: 8}}>Scene {index+1}</div>
                                <div>{scene.text}</div>
                            </div>
                        )
                    }
                    </div> : 
                    <div style={{width: '95%', margin: 'auto', marginTop: 30, }}>
                        {/* <div style={{margin: 10, fontSize: 18,  fontWeight: 700}}>Script </div> */}
                        <textarea
                            name='test'
                            className='script-container'
                            value={script}    
                            onChange={handleScriptChange}
                        />
        
                        <button className='btn create-btn' onClick={updateScript}>Update Script <i style={{position: 'absolute', right: 5, fontSize: 14}} class="bi bi-chevron-right"></i></button>
                    </div>
            }
            {/* <div style={{overflow: 'scroll', marginTop: 10, height: '95%', overflow: 'scroll'}}>
                {
                    scenes.map((scene, index) => 
                        <div style={{padding: 8, border: activeSceneIndex == index ? '3px solid #5290D6': '1px solid #eee', borderRadius: 5, width: '90%', cursor: 'pointer', margin: 'auto', marginTop: 10 }} onClick={() => changeActiveScene(index)}>
                            <div style={{fontSize: 13, color: activeSceneIndex == index ? '#5290D6' : '#000', fontWeight: 700, marginBottom: 8}}>Scene {index}</div>
                            <div>{scene.text}</div>
                        </div>
                    )
                }
            </div> */}

            
        </div>
    )
}

export default StoryOptions
