import React, {useState, useEffect, useRef} from 'react'
import Dropdown from './Dropdown';
import { useNavigate, useParams } from "react-router-dom";
import { createNewProject } from '../utils/createUtils';
import '../css/input.css'
import { captions } from '../constants/captionStyles'; 
import { availableLanguages, voices } from '../constants/speechConfig';
import uuid from 'react-uuid';


const InputView = ({active, setActive}) => {
    const navigate = useNavigate()
    const audioRef = useRef()
    const inputRef = useRef()

    const { projectId } = useParams()

    const [isPlaying, setIsPlaying] = useState(false)

    const [textBlob, setTextBlob] = useState('')
    const [caption, setCaption] = useState('')

    const [isMenuOpen, setMenuOpen] = useState(false)
    const [selectedVoice, setSelectedVoice] = useState(null)

    const [activeVoice, setActiveVoice] = useState()
    const [language, setLanguage] = useState('')
    const [langCode, setLangCode] = useState('')

    const [voiceOptions, setVoiceOptions] = useState([])
    const [langauageOptions, setLangauageOptions] = useState()

    const [selectedCaptionIdx, setSelectedCaptionIdx] = useState(0)

    const genderOptions = [
        {value: 'All', label: 'All'},
        {value: 'Male', label: 'Male'},
        {value: 'Female', label: 'Female'}
    ]

    const Icon = () => {
        return (
          <svg height="20" width="20" viewBox="0 0 20 20">
            <path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path>
          </svg>
        );
      };

    useEffect(() => {
        if(!langauageOptions && availableLanguages){
            var voiceList = []
            availableLanguages.map((lang) => {
                voiceList.push({label: lang, value: lang})
            })
            setLangauageOptions(voiceList)
        }
    }, [])

    useEffect(() => {
        const handler = (e) => {
          if (inputRef.current && !inputRef.current.contains(e.target)) {
            setMenuOpen(false);
          }
        };
    
        window.addEventListener("click", handler);
        return () => {
          window.removeEventListener("click", handler);
        };
      });

    const handleInput = (e) => {
        const value = e.target.value
        setTextBlob(value)
    }

    const toggleMenu = () => {
        setMenuOpen(!isMenuOpen)
    }

    const selectVoice = (voice) => {
        console.log('VOICE SELECTED: ', voice)
        setSelectedVoice(voice)
        setMenuOpen(false)
    }

    const handleClick=(e, voice)=>{
        e.stopPropagation();
        console.log('PLAYING AUDIO')
        var audioElm = audioRef.current
        if(isPlaying && activeVoice == voice.ShortName){
            setIsPlaying(false)
            audioElm.pause()
        }else{
          setIsPlaying(true)
          setActiveVoice(voice.ShortName)

          audioElm.src = `https://res.cloudinary.com/dlmsxlf2n/video/upload/v1723737009/Diveo/Assets/audio/voice/${voice.ShortName}.wav`;
          audioElm.volume = 0.5
          audioElm.play()
        }
    }


    const endMusic = (e) => {
        setIsPlaying(false)
    }

    const getVoiceOptions = (language) => {
        console.log('LANG: ', language)
        var voiceList = voices.filter((obj) => {
            return language == obj.LocaleName;
        });
        setVoiceOptions(voiceList)
        setSelectedVoice(voiceList[0])
        console.log('DiSPLAY VOICES: ', voiceList)
    }

    const selectLanguage = (lang) => {
        console.log('LANGUAGE SELECTED: ', lang)
        setLanguage(lang.value)

        var languageCode = getLanguageCode(lang.value)
        console.log('LANG CODE: ', languageCode)
        setLangCode(languageCode)

        getVoiceOptions(lang.value)
    }

    const selectCaption = (captionIdx) => {
        setSelectedCaptionIdx(captionIdx)
    }

    const getLanguageCode = (language) => {
        var languageCode;
        for(let i=0; i < voices.length; i++){
            const voice = voices[i]
            if(voice.LocaleName == language){
                languageCode = voice.Locale;
                break;
            }
        }
        return languageCode;

    }


    const triggerStoryCreation = async () => {
        console.log('TRIGGER STORY CREATION::')
        console.log('VOICE: ', selectedVoice)

        var languages = new Set()
        
        voices.map((voice, index) => {
            languages.add(voice.LocaleName)
        });

        const projectId = uuid()

        console.log('Langauge List: ', languages)
        console.log('Voice Count: ', voices.length)
        console.log('Language Count: ', languages.size)
        createNewProject(textBlob, projectId, selectedVoice.value, langCode, selectedCaptionIdx)
        navigate(`/edit/`);
    }

    return (
        <div style={{height: '92vh', width: '100%'}}>
            <div style={{width: '95%', height: '92vh', margin: 'auto', borderRadius: 10, backgroundColor: '#fff'}}>
                <audio ref={audioRef} onEnded={endMusic}></audio>

                <div style={{ marginTop: 20, height: '10vh'}}>
                    <div style={{float: 'left', marginLeft: 20, marginTop: 10, color: '#3b3b3b', fontSize: 24, fontWeight: 700}}>New Project</div>
                    <button className='btn create-btn' onClick={triggerStoryCreation}>Create <i style={{fontSize: 14}} class="bi bi-chevron-right"></i></button>
                </div>
                <div style={{height: '80vh', padding: 10}}>
                    <div style={{ display: 'inline-block', width: '45%', height: '100%', overflowY: 'scroll'}}>
                        <div style={{ width: '95%', margin: 'auto', height: '100%', marginLeft: 10, padding: 10}}>
                            <span style={{border: '1px solid #5b44ed', fontSize: 10, color: '#5b44ed', borderRadius: 10, padding: 2, paddingInline: 8}}>Step 1</span>
                            <div style={{marginTop: 5, marginBottom: 5, fontWeight: 800, fontSize: 18, fontFamily: 'Open Sans', color: '#5b44ed'}}>Script</div>
                            <div style={{height: '85%', marginTop: 15}}>
                                <textarea
                                    name='test'
                                    className='text-container'
                                    placeholder='Type or paste your script here...'
                                    onChange={handleInput}
                                />
                            </div>
                        </div>
                    </div>
                    <div style={{display: 'inline-block', height: '100%', margin: 10,  border: '1px dashed #eee'}}></div>
                    <div style={{ display: 'inline-block', width: '45%', height: '100%', overflowY: 'scroll'}}>

                        <div style={{ width: '95%', margin: 'auto', marginTop: 20, padding: 10}}>
                            <span style={{border: '1px solid #5b44ed', fontSize: 10, color: '#5b44ed', borderRadius: 10, padding: 2, paddingInline: 8}}>Step 2</span>
                            <div style={{marginTop: 5, marginBottom: 5, fontWeight: 800, fontSize: 18, fontFamily: 'Open Sans', color: '#5b44ed'}}>Voiceover</div>
                                <div style={{marginTop: 15, fontSize: 14, marginBottom: 8, fontWeight: 700, color: '#424242'}}>Language</div>
                                <div style={{ width: '100%', maxWidth: 350, marginRight: 5}}>
                                    <Dropdown
                                        isSearchable={false}
                                        menuSize={'100%'}
                                        placeHolder='Select Language'
                                        options={langauageOptions}
                                        onChange={(value) => selectLanguage(value)}
                                    />
                                </div>
                                <div style={{marginTop: 15, fontSize: 14, marginBottom: 8, fontWeight: 700, color: '#424242'}}>Voice</div>
                                <div style={{ width: '100%', marginRight: 5}}>
                                    <div ref={inputRef} style={{width: '100%', maxWidth: 350,  border: '1px solid #eee', cursor: 'pointer', position: 'relative',  padding: 8, borderRadius: 4, fontSize: 15, fontFamily: 'PT Sans'}}>
                                        <div style={{width: '100%'}} onClick={toggleMenu}>
                                            {selectedVoice  ? selectedVoice.DisplayName : 'Select Voice'}
                                            <div style={{position: 'absolute', right: 10, top: 8}}>
                                                <Icon />
                                            </div>
                                        </div>
                                        <div  style={{ display: isMenuOpen ? 'block' : 'none', width: '100%', maxWidth: 350, maxHeight: 300, position: 'absolute', paddingTop: 5, top: 45, left: 0, border: '1px solid #eee', backgroundColor: '#fff', borderRadius: 4, overflow: 'scroll'}} >
                                            { voiceOptions.map((voice) => 
                                                <div className={selectedVoice.DisplayName == voice.DisplayName ? 'dropdown-selected-item' : 'dropdown-menu-item'} onClick={() => selectVoice(voice)}>
                                                    <div style={{float: 'left',padding: 10}}>{voice.DisplayName}</div>
                                                    <div style={{float: 'right', padding: 5, marginRight: 10, fontSize: 26}} onClick={(e)=>handleClick(e, voice)}>
                                                        {
                                                            isPlaying && activeVoice == voice.ShortName ?
                                                                <i class="bi bi-stop-circle-fill"></i> :
                                                                <i class="bi bi-play-fill"></i> 

                                                        }
                                                        </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    
                                

                                </div>
                        
                        </div>
                        <div style={{display: 'inline-block', width: '100%', marginTop: 20,  border: '1px dashed #eee'}}></div>
                        <div style={{ width: '95%', margin: 'auto', marginTop: 5, padding: 10, height: 150}}>
                            <span style={{border: '1px solid #5b44ed', fontSize: 10, color: '#5b44ed', borderRadius: 10, padding: 2, paddingInline: 8}}>Step 3</span>
                            <div style={{marginTop: 5, marginBottom: 5, fontWeight: 800, fontSize: 18, fontFamily: 'Open Sans', color: '#5b44ed'}}>Caption Presets</div>
                            {
                                captions.map((caption, index) => 
                                    <div style={{display: 'inline-block', width: 129, height: 78, padding: 2, margin: '2%', border: index === selectedCaptionIdx ? '3px solid #5290D6': null, borderRadius: 15, cursor: 'pointer'}} onClick={() => selectCaption(index)}>
                                        <video
                                            autoPlay  
                                            muted
                                            no-controls
                                            loop
                                            style={{ margin: 'auto',  width: '100%', border: '1px solid #eee', borderRadius: 15}}
                                            src={caption.url}
                                        />
                                    </div>
                                )
                            }
                        </div>
                        {/* <div style={{width: '95%', margin: 'auto', marginTop: 20, padding: 10, marginTop: 20, height: 300}}>
                            <span style={{border: '1px solid #5b44ed', fontSize: 12, color: '#5b44ed', borderRadius: 10, padding: 2, paddingInline: 8}}>Step 3</span>
                            <div style={{marginTop: 5, marginBottom: 5, fontWeight: 800, fontSize: 22, fontFamily: 'Open Sans', color: '#5b44ed'}}>Captions</div>
                        </div> */}
                        
                    </div>
                </div>
                
            
                
            </div>
   
        </div>
        )
}

export default InputView
