import { db } from "../configs/firebaseConfig";
import {
  collection,
  doc,
  addDoc,
  setDoc,
  getDocs,
  Timestamp,
  query,
  orderBy,
  onSnapshot,
} from "firebase/firestore";
import { store } from "../redux/store";
import axios from "axios";

const getFileType = (fileData) => {
  var file = fileData;
  console.log("File: ", file);
  var type = file.type.split("/")[0];
  console.log("Type: ", type);
  if (type == "video") {
    return "video";
  } else if (type == "image") {
    return "image";
  } else {
    return;
  }
};

async function uploadAndGetThumbnailUrl(file) {
  const images = new FormData();
  images.append("file", file);
  images.append("upload_preset", "eweiesmw");

  var res = await axios.post(
    "https://api.cloudinary.com/v1_1/dlmsxlf2n/image/upload",
    images
  );

  console.log("Successfully uploaded images to cloudinary");
  console.log("Uploaded Thumbnail: ", res);
  const thumbnail = res["data"]["url"];
  return thumbnail;
}

export async function getAllProjectsForUser() {
  var state = store.getState();

  var userId = state.authReducer.user.id;
  console.log("User Id ===> ", userId);

  const collectionRef = collection(db, "users", userId, "projects");
  const q = query(collectionRef, orderBy("updated", "desc"));

  const querySnapshot = await getDocs(q);

  let projects = [];
  querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
    console.log(doc.id, " => ", doc.data());
    projects.push({ ...doc.data(), id: doc.id });
  });

  return projects;
}

export async function syncProjectDetails() {
  //   const userDocRef = doc(db, "users", uid);
  //   const projectsRef = collection(userDocRef, "projects");

  const state = store.getState();
  const storyReducer = state.storyReducer;

  const userId = state.authReducer.user.id;
  console.log("User Id ===> ", userId);

  const projectId = storyReducer.settings.projectId;
  console.log("Project Id: ", projectId);

  const thumbnailFile = storyReducer.assets[0]?.thumbnail;
  const thumbnailFileUrl = await uploadAndGetThumbnailUrl(thumbnailFile);
  console.log("Thumbnail FIle: ", thumbnailFileUrl);

  const storyDetails = {
    timelineInfo: storyReducer.timelineInfo,
    settings: storyReducer.settings,
    sceneInfoArray: storyReducer.sceneInfoArray,
    captionStyle: storyReducer.captionStyle,
    audioInfo: storyReducer.audioInfo,
  };

  var projectData = {
    projectId: projectId,
    storyInfo: storyDetails,
    thumbnail: thumbnailFileUrl,
    updated: Timestamp.now(),
  };
  console.log("Project Data: ", projectData);

  const docRef = doc(db, "users", userId, "projects", projectId);
  await setDoc(docRef, projectData);
}

export async function uploadAssetToCloudAndSaveInDB(userId, files) {
  console.log("Uploading Files to Cloudinary");

  console.log("Files: ", files);

  // const assetsRef = collection(userDocRef, "assets");

  const images = new FormData();
  const videos = new FormData();

  var imageCount = 0;
  var videoCount = 0;

  files.map((file) => {
    const fileType = getFileType(file);
    if (fileType == "image") {
      images.append("file", file);
      imageCount++;
    } else if (fileType == "video") {
      videos.append("file", file);
      videoCount++;
    } else {
      // Invalid File Type
      console.log("Invalid File Type: \n", file);
    }
  });

  images.append("upload_preset", "eweiesmw");
  videos.append("upload_preset", "eweiesmw");

  if (imageCount) {
    axios
      .post("https://api.cloudinary.com/v1_1/dlmsxlf2n/image/upload", images)
      .then((res) => {
        console.log("Successfully uploaded images to cloudinary");
        console.log(res);
        var data = res["data"];
        /* Props: mediaType, url, height, width */
        var imageData = {
          mediaType: "image",
          url: data["url"],
          width: data["width"],
          height: data["height"],
          created: Timestamp.now(),
        };

        console.log("Image Data: ", imageData);

        const userDocRef = doc(db, "users", userId);
        const assetsRef = collection(userDocRef, "assets");
        addDoc(assetsRef, imageData);
      });
  }

  if (videoCount) {
    axios
      .post("https://api.cloudinary.com/v1_1/dlmsxlf2n/video/upload", videos)
      .then((res) => {
        console.log("Successfully uploaded videos to cloudinary");
        console.log(res);

        var data = res["data"];
        /* Props: mediaType, duration, thumbnail
              files
              -> p_480 
                --> url 
                --> height 
                --> width 
          */
        var videoData = {
          mediaType: "video",
          created: Timestamp.now(),
          duration: data["duration"],
          thumbnail:
            data["url"].substr(0, data["url"].lastIndexOf(".")) + ".jpg",
          url: data["url"],
          width: data["width"],
          height: data["height"],
        };

        console.log("Video Data: ", videoData);

        console.log("User Id: ", userId);
        const userDocRef = doc(db, "users", userId);
        const assetsRef = collection(userDocRef, "assets");
        addDoc(assetsRef, videoData);

        // Get upload data and save it in db for the user
      });
  }
}

export async function getAllAssetsForUser(userId) {
  console.log("Fetching Assets For user: ", userId);
  const collectionRef = collection(db, "users", userId, "assets");
  const querySnapshot = query(collectionRef, orderBy("created"));
  let assets = [];
  console.log("Query Snapshot: ", querySnapshot);
  const unsubscribe = onSnapshot(querySnapshot, (docs) => {
    console.log("DOCS: ", docs);
    docs.forEach((doc) => {
      console.log("DOC: ", doc);
      console.log(doc.id, " => ", doc.data());
      assets.push({ ...doc.data(), id: doc.id });
    });
  });

  console.log("UNSUBSCRIBE: ", unsubscribe);
  return assets;
}
