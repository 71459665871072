import { store } from "../redux/store";
import {
  initNewState,
  updateSettings,
  setAudioInfo,
  setCaptionStyle,
} from "../redux/storySlice";
import { processTextToSpeech } from "../services/speech";
import { captions } from "../constants/captionStyles";

function initNewProject() {
  store.dispatch(initNewState({}));
}

function createSceneBounds(sentenceRangeMap, audioDuration) {
  var sceneBoundsArray = [];

  sentenceRangeMap.map((obj, index) => {
    const endTime =
      index == sentenceRangeMap.length - 1
        ? audioDuration
        : sentenceRangeMap[index + 1].startTime;
    var sceneInfo = {
      id: index,
      text: obj.text,
      start: obj.startTime,
      end: endTime,
      duration: endTime - obj.startTime,
    };
    sceneBoundsArray.push(sceneInfo);
  });
  console.log("SCENES: ", sceneBoundsArray);
  return sceneBoundsArray;
}

async function createNewProject(text, projectId, voice, langCode, captionIdx) {
  console.log("CREATING NEW PROJECT");
  initNewProject();

  const settings = { projectId: projectId, aspectRatio: "1/1", quality: 720 };
  const captionStyle = captions[captionIdx].style;
  const result = await processTextToSpeech(projectId, text, voice, langCode);
  console.log("AUDIO INFO: ", result.data);
  if (result) {
    store.dispatch(updateSettings({ settings: settings }));
    store.dispatch(setAudioInfo({ audioInfo: result.data }));
    store.dispatch(setCaptionStyle({ captionStyle: captionStyle }));
  }
}

export { initNewProject, createSceneBounds, createNewProject };
