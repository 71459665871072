import React, {useState} from 'react'
import {useAuthState} from 'react-firebase-hooks/auth'
import { auth } from "../configs/firebaseConfig";
import useSubscribedStatus from '../stripe/useSubscribedStatus'
import { createCheckoutSession } from '../stripe/createCheckoutSession'

import { useLocation, useNavigate } from "react-router-dom";
import '../css/main.css';

const PricingPage = () => {
    const navigate = useNavigate();
    const [user, userLoading] = useAuthState(auth)
    const isUserSubscribed = useSubscribedStatus(user)
    const [disabled, setDisabled] = useState(false)

    const checkSubscription = (plan) => {
        setDisabled(true)
        if(plan == 'Free'){
            navigate("/home");
        }
        console.log('User: ', user)
        if(!user && !userLoading){
            // Navigate to login page
            navigate('/login')
        }
        if(user && !userLoading){
            if(!isUserSubscribed){
                createCheckoutSession(user.uid)
            }else{
                console.log('User has already subscribed')
                navigate('/home')
            }
        }
    }

    return (
        <div>
            <div class="header">
                <div class="inner-header flex">
                <section>
                    <center><h1 style={{fontSize: '2.5em', fontFamily: 'Open Sans, sans-serif', color: '#212529', marginBottom: 50, marginTop: 40}}>Simple, easy plans.</h1></center>
                        <div class="pricing pricing-palden">
                        <div class="pricing-item features-item ja-animate" data-animation="move-from-bottom" data-delay="item-0" style={{minHeight: '437px'}}>
                            <div class="pricing-deco">
                                {/* <svg class="pricing-deco-img" enable-background="new 0 0 300 100" height="100px" id="Layer_1" preserveAspectRatio="none" version="1.1" viewBox="0 0 300 100" width="300px" x="0px" xml:space="preserve" y="0px">
                                    <path class="deco-layer deco-layer--1" d="M30.913,43.944c0,0,42.911-34.464,87.51-14.191c77.31,35.14,113.304-1.952,146.638-4.729c48.654-4.056,69.94,16.218,69.94,16.218v54.396H30.913V43.944z" fill="#FFFFFF" opacity="0.6"></path>
                                    <path class="deco-layer deco-layer--2" d="M-35.667,44.628c0,0,42.91-34.463,87.51-14.191c77.31,35.141,113.304-1.952,146.639-4.729c48.653-4.055,69.939,16.218,69.939,16.218v54.396H-35.667V44.628z" fill="#FFFFFF" opacity="0.6"></path>
                                    <path class="deco-layer deco-layer--3" d="M43.415,98.342c0,0,48.283-68.927,109.133-68.927c65.886,0,97.983,67.914,97.983,67.914v3.716H42.401L43.415,98.342z" fill="#FFFFFF" opacity="0.7"></path>
                                    <path class="deco-layer deco-layer--4" d="M-34.667,62.998c0,0,56-45.667,120.316-27.839C167.484,57.842,197,41.332,232.286,30.428c53.07-16.399,104.047,36.903,104.047,36.903l1.333,36.667l-372-2.954L-34.667,62.998z" fill="#FFFFFF"></path>
                                </svg> */}
                                <div class="pricing-price" style={{color: '#212529'}}><span class="pricing-currency"></span>$0
                                    <span class="pricing-period"></span>
                                </div>
                                <h3 class="pricing-title" style={{color: '#212529'}}>Free</h3>
                            </div>
                            <ul class="pricing-feature-list">
                                <li class="pricing-feature"><span class="feature-check-icon"><i class="bi bi-check2"></i></span>No credit card required</li>
                                <li class="pricing-feature"><span class="feature-check-icon"><i class="bi bi-check2"></i></span>Exports with a watermark</li>
                                <li class="pricing-feature"><span class="feature-check-icon"><i class="bi bi-check2"></i></span>720p exports</li>
                            </ul>
                            <button class="pricing-action" onClick={() => checkSubscription('Free')}>Get Started <i class="bi bi-arrow-right"></i></button>
                        </div>
                        <div class="pricing-item features-item ja-animate pricing__item--featured" data-animation="move-from-bottom" data-delay="item-1" style={{minHeight: '457px'}}>
                            <div class="pricing-deco" style={{background: '#212529'}}>
                                {/* <svg class="pricing-deco-img" enable-background="new 0 0 300 100" height="100px" id="Layer_1" preserveAspectRatio="none" version="1.1" viewBox="0 0 300 100" width="300px" x="0px" xml:space="preserve" y="0px">
                                    <path class="deco-layer deco-layer--1" d="M30.913,43.944c0,0,42.911-34.464,87.51-14.191c77.31,35.14,113.304-1.952,146.638-4.729c48.654-4.056,69.94,16.218,69.94,16.218v54.396H30.913V43.944z" fill="#FFFFFF" opacity="0.6"></path>
                                    <path class="deco-layer deco-layer--2" d="M-35.667,44.628c0,0,42.91-34.463,87.51-14.191c77.31,35.141,113.304-1.952,146.639-4.729c48.653-4.055,69.939,16.218,69.939,16.218v54.396H-35.667V44.628z" fill="#FFFFFF" opacity="0.6"></path>
                                    <path class="deco-layer deco-layer--3" d="M43.415,98.342c0,0,48.283-68.927,109.133-68.927c65.886,0,97.983,67.914,97.983,67.914v3.716H42.401L43.415,98.342z" fill="#FFFFFF" opacity="0.7"></path>
                                    <path class="deco-layer deco-layer--4" d="M-34.667,62.998c0,0,56-45.667,120.316-27.839C167.484,57.842,197,41.332,232.286,30.428c53.07-16.399,104.047,36.903,104.047,36.903l1.333,36.667l-372-2.954L-34.667,62.998z" fill="#FFFFFF"></path>
                                </svg> */}
                                <div class="pricing-price"><span class="pricing-currency"></span>$39
                                    <span class="pricing-period">/ month</span>
                                </div>
                                <h3 class="pricing-title">Premium</h3>
                            </div>
                            <ul class="pricing-feature-list">
                                <li class="pricing-feature"><span class="feature-check-icon"><i class="bi bi-check2"></i></span>30 exports/month</li>
                                <li class="pricing-feature"><span class="feature-check-icon"><i class="bi bi-check2"></i></span>No watermark</li>
                                <li class="pricing-feature"><span class="feature-check-icon"><i class="bi bi-check2"></i></span>Full HD 1080p exports</li>
                            </ul>
                            <button class="pricing-action" disabled={disabled} onClick={() => checkSubscription('Premium')}>Get Started <i class="bi bi-arrow-right"></i></button>
                        </div>
                       
                        </div>
                    </section>
                </div>
               
                </div>
                
        </div>
    )
}

export default PricingPage
