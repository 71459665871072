import React, {useState, useEffect, useCallback} from 'react'
import '../css/editor.css';
import {useDropzone} from 'react-dropzone'
import axios from 'axios'
import { uploadAssetToCloudAndSaveInDB } from '../services/firebaseStore';
import { authUser } from '../constants/data';

const UploadBox = ({setLoading}) => {

    const [files, setFiles] = useState([])

    const onDrop = useCallback(acceptedFiles => {
        console.log('Accepted Files: ', acceptedFiles)
        if(acceptedFiles?.length){
            console.log('User: ', authUser)
            // uploadAssetToCloudAndSaveInDB(authUser?.uid, acceptedFiles)
            uploadFiles(authUser?.uid, acceptedFiles)
        }

    }, [])


    const {getRootProps, getInputProps, isDragActive} = useDropzone({
        onDrop, 
        accept: {
            'image/*': [],
            'video/*': [],
        },
        maxSize: 1024*1000*20
    })

    const uploadFiles = async (uid, acceptedFiles) => {
        setLoading(true)
        await uploadAssetToCloudAndSaveInDB(uid, acceptedFiles)
        setLoading(false)
    }

    return (
        <div style={{width: '90%',  marginTop: 20, margin: 'auto',}}>
            {/* <h2 style={{color: '#6CB4EE'}}>Upload Files</h2> */}
            <div {...getRootProps({
                className: 'p-40 mt-10 border-dashed-2 upload-block'
            })}>
            <input {...getInputProps()} />
            {
                isDragActive ?
                    <p>Click to browse, or ...</p> :
                    <div>
                        <h5 style={{ fontSize: 18, fontWeight: 600, textAlign: 'center'}}>Upload a file</h5>
                        <p style={{fontSize: 16, textAlign: 'center'}}>Click to browser, or drag and drop a file here</p>
                    </div>
                
            }
            </div>
                
        </div>
    )
}

export default UploadBox
