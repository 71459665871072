import React, { useEffect } from "react";
import "./App.css";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Main from "./pages/Main";
import Anime from "./components/Anime";
import EditorView from "./components/EditorView";
import Auth from "./components/Auth";
import Logout from "./components/Logout";
import RenderControl from "./components/RenderControl";
import InputView from "./components/InputView";
import Preview from "./components/Preview";
import { useIsFontListLoaded } from "./hooks/useIsFontListLoaded";
import ExportView from "./components/ExportView";
import HomePage from "./pages/HomePage";
import ProjectView from "./components/ProjectView";
import PricingPage from "./pages/PricingPage";

const App = (props) => {
  const isFontListLoaded = useIsFontListLoaded();

  return (
    <React.Fragment>
      <Router>
        <Routes>
          <Route path="/login" element={<Auth />} />
          <Route path="/logout" element={<Logout />} />
          <Route path="/" element={<Main />} exact={true} />
          <Route path="/player" element={<Anime />} exact={true} />
          <Route path="/edit" element={<EditorView />} exact={true} />
          <Route path="/render" element={<RenderControl />} exact={true} />
          <Route path="/preview" element={<Preview />} exact={true} />
          <Route path="/new" element={<InputView />} exact={true} />
          <Route path="/home" element={<HomePage activeMenuIndex={0} />} />
          <Route path="/projects" element={<ProjectView />} />
          <Route path="/pricing" element={<PricingPage />} />
        </Routes>
      </Router>
    </React.Fragment>
  );
};

export default App;
