const captions = [
  {
    id: 0,
    url: "https://res.cloudinary.com/dlmsxlf2n/video/upload/v1724485066/Diveo/Assets/caption-presets/caption-preset-1.mp4",
    style: {
      fill: "#fff",
      fontFamily: "Arial",
      fontSize: 24,
      fontWeight: 700,
      padding: 4,
      top: 300,
      left: 200,
      textAlign: "center",
    },
  },
  {
    id: 1,
    url: "https://res.cloudinary.com/dlmsxlf2n/video/upload/v1724485067/Diveo/Assets/caption-presets/caption-preset-2.mp4",
    style: {
      animWord: {
        textBackgroundColor: "#FF25AD",
      },
      restoreState: true,
      borderRadius: 5,
      fill: "#fff",
      backgroundColor: null,
      fontFamily: "Raleway",
      fontSize: 24,
      fontWeight: 700,
      padding: 4,
      top: 300,
      left: 200,
      textAlign: "center",
    },
  },
  {
    id: 2,
    url: "https://res.cloudinary.com/dlmsxlf2n/video/upload/v1724485066/Diveo/Assets/caption-presets/caption-preset-3.mp4",
    style: {
      animWord: {
        fill: "#000",
      },
      restoreState: false,
      borderRadius: 5,
      fill: "#ccc",
      backgroundColor: "#eee",
      fontFamily: "Roboto",
      fontSize: 24,
      fontWeight: 700,
      padding: 4,
      top: 300,
      left: 200,
      textAlign: "center",
    },
  },
];

export { captions };
