export const sceneContainerHeight = 337.5;
export const sceneContainerWidth = 600;

export function getFrameCoordinates(aspectRatio) {
  var frameCoordinates = {};
  switch (aspectRatio) {
    case "1/1":
      frameCoordinates.x = (sceneContainerWidth - sceneContainerHeight) / 2;
      frameCoordinates.y = 0;
      frameCoordinates.width = sceneContainerHeight;
      frameCoordinates.height = sceneContainerHeight;
      break;
    case "9/16":
      frameCoordinates.x =
        (sceneContainerWidth - (9 / 16) * sceneContainerHeight) / 2;
      frameCoordinates.y = 280;
      frameCoordinates.width = (9 / 16) * sceneContainerHeight;
      frameCoordinates.height = sceneContainerHeight;
      break;
    case "16/9":
    default:
      frameCoordinates.x =
        (sceneContainerWidth - (16 / 9) * sceneContainerHeight) / 2;
      frameCoordinates.y = 280;
      frameCoordinates.width = (16 / 9) * sceneContainerHeight;
      frameCoordinates.height = sceneContainerHeight;
      break;
  }
  return frameCoordinates;
}

export function getTextParamsForAspectRatio(
  aspectRatio,
  frameWidth,
  frameHeight,
  position
) {
  var textParams = {};

  switch (aspectRatio) {
    case "1/1":
      textParams.x = 0.05 * frameWidth;
      textParams.y = frameHeight * 0.1;
      textParams.width = 0.9 * frameWidth;
      textParams.fontSize = 18;
      textParams.lineHeight = 1.2;
      textParams.paddingX = 5;
      textParams.paddingY = 5;
      break;

    case "9/16":
      textParams.x = 0.05 * frameWidth;
      textParams.y = 0.15 * frameHeight;
      textParams.width = 0.9 * frameWidth;
      textParams.fontSize = 15;
      textParams.lineHeight = 1.1;
      textParams.paddingX = 5;
      textParams.paddingY = 5;
      break;
    case "16/9":
    default:
      textParams.x = 0.15 * frameWidth;
      textParams.y = 0.15 * frameHeight;
      textParams.width = 0.8 * frameWidth;
      textParams.fontSize = 20;
      textParams.lineHeight = 1.5;
      textParams.paddingX = 10;
      textParams.paddingY = 10;
      break;
  }

  return textParams;
}

export function getPreviewContainerDimensions(aspectRatio) {
  var containerHeight = 445;
  var containerWidth = 800;
  var containerParams = {
    height: containerHeight,
    width: containerWidth,
  };
  switch (aspectRatio) {
    case "1/1":
      containerParams.height = containerHeight;
      containerParams.width = containerWidth;
      containerParams.viewHeight = containerHeight - 40;
      containerParams.viewWidth = containerHeight - 40;
      containerParams.viewX = containerWidth / 2 - (containerHeight - 2) / 2;
      containerParams.viewY = 20;
      containerParams.fontSize = 18;
      containerParams.scaleX = 1;
      containerParams.scaleY = 1;
      break;
    case "9/16":
      var viewHeight = containerHeight - 40;
      var viewWidth = (containerHeight - 40) * (9 / 16);

      containerParams.height = containerHeight;
      containerParams.width = containerWidth;
      containerParams.viewHeight = viewHeight;
      containerParams.viewWidth = viewWidth;
      containerParams.viewX = containerWidth / 2 - viewWidth / 2;
      containerParams.viewY = 20;
      containerParams.fontSize = 14;
      containerParams.scaleX = 1;
      containerParams.scaleY = 1;
      break;
    case "16/9":
    default:
      var viewHeight = containerHeight - 40;
      var viewWidth = (containerHeight - 40) * (16 / 9);
      containerParams.height = containerHeight;
      containerParams.width = containerWidth;
      containerParams.viewHeight = viewHeight;
      containerParams.viewWidth = viewWidth;
      containerParams.viewX = containerWidth / 2 - viewWidth / 2;
      containerParams.viewY = 20;
      containerParams.fontSize = 21;
      containerParams.scaleX = 1;
      containerParams.scaleY = 1;
      break;
  }

  return containerParams;
}

export function getScreenDimensionsForQuality(quality, aspect) {
  var dims;
  switch (quality) {
    case 480:
      if (aspect == "16/9") {
        dims = { height: 480, width: 850 };
      } else if (aspect == "1/1") {
        dims = { height: 480, width: 480 };
      } else if (aspect == "9/16") {
        dims = { height: 480, width: 270 };
      }
      break;
    case 720:
      if (aspect == "16/9") {
        dims = { height: 720, width: 1280 };
      } else if (aspect == "1/1") {
        dims = { height: 720, width: 720 };
      } else if (aspect == "9/16") {
        dims = { height: 720, width: 405 };
      }
      break;
    case 1080:
      if (aspect == "16/9") {
        dims = { height: 1080, width: 1920 };
      } else if (aspect == "1/1") {
        dims = { height: 1080, width: 1080 };
      } else if (aspect == "9/16") {
        dims = { height: 1080, width: 607.5 };
      }
      break;
    default:
      if (aspect == "16/9") {
        dims = { height: 480, width: 850 };
      } else if (aspect == "1/1") {
        dims = { height: 480, width: 480 };
      } else if (aspect == "9/16") {
        dims = { height: 480, width: 270 };
      }
  }
  return dims;
}
