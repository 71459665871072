import React, {useState, useEffect} from 'react'
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux'
import '../css/input.css'
import MenuBar from '../components/MenuBar';
import Sidebar from '../components/Sidebar';
import ProjectView from '../components/ProjectView';
import InputView from '../components/InputView';

const HomePage = ({activeMenuIndex}) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [createType, setCreateType] = useState()
    const [aspectRatio, setAspectRatio] = useState('16/9')

    const [active, setActive] = useState(0);

    const [isSettingsModalActive, setSettingsModalActive] = useState(false)
    
    useEffect(() => {
       if(active){
           console.log('Active Menu Index: ', active)
           console.log('History Stack', window.history)
       }
    }, [active])

    useEffect(() => {
        if(activeMenuIndex){
            setActive(activeMenuIndex)
        }
    }, [activeMenuIndex])

    const triggerSceneCreationFromScratch = async () => {
        // await refreshTemplateData()
      
        // dispatch(setAspectRatio({ aspectRatio: '16/9' }));
        // dispatch(setJobId({ jobId: uuid() }));
        // dispatch(setFps({ fps: 30 }));

        // await buildStoryFromScratch();

        // navigate(`/storyboard/v2`);
    }
    

    const pageContent = () => {
        switch(active){
            case 0:
                window.history.pushState(null, "", "/home")
                return <InputView active={active} setActive={setActive} />
            case 1:
                window.history.pushState(null, "", "/projects")
                return <ProjectView />
        }
    }

    const openSettingsModal = () => {
        setSettingsModalActive(true)
    }

    const closeSettingsModal = () => {
        setSettingsModalActive(false)
    }

    return (
        <>
            <MenuBar />
            <div style={{height: '92vh', overflow: 'scroll'}}>
                <Sidebar active={active} setActive={setActive}/>
                <div className='options-container'>
                    {pageContent()}
                </div>
            </div>
           
          
          
        </>
    )
}

export default HomePage
