import { store } from "../redux/store";
import { fabric } from "fabric";
import { updateObjectInfo } from "../redux/storySlice";
import {
  addOrReplaceVideo,
  renderObjectsFromConfig,
  addObjectsFromTimeline,
} from "../utils/renderUtils";
import { CustomTextbox } from "../constants/customFabric";

const playerModes = {
  PLAYER: "player",
  PREVIEW: "preview",
  SCENE_TRACKER: "scene-tracker",
  RENDER_CONTROL: "render-control",
};

const isVideoReady = (id, videoRef) => {
  return new Promise((resolve) => {
    console.log("VIDEO:", videoRef.current[id]);
    if (videoRef.current[id]) {
      var handle = setInterval(() => {
        var video = videoRef.current[id];
        if (video.readyState == 4) {
          console.log("State ==>", video.readyState);
          clearInterval(handle);
          console.log("VIDEO READY!!");
          resolve();
        }
      }, 100);
    } else {
      resolve();
    }
  });
};

const composeScene = async (
  sceneInfo,
  audioInfo,
  timeline,
  canvas,
  containerParams,
  clipPathRef,
  videoRef,
  textRef,
  captionStyle,
  wordTimestamps,
  wordsIndexTracker,
  wordIndexInString,
  processType
) => {
  console.log("Render Scene: ", sceneInfo);
  var renderConfig = [];

  // var canvas = fabricRef.current[sceneInfo.id]
  if (canvas.getObjects()) {
    canvas.remove(...canvas.getObjects().concat());
  }

  var index = 1;

  console.log("Scene Info: ", sceneInfo);
  const startTime = sceneInfo.start;

  await addObjectsFromTimeline(
    timeline,
    sceneInfo.id,
    startTime,
    containerParams,
    canvas,
    videoRef,
    clipPathRef,
    index++,
    renderConfig
  );

  // console.log('CLIP PATH: ', clipPathRef.current)
  if (clipPathRef && clipPathRef.current) {
    renderConfig.push({
      id: "clip-path",
      obj: clipPathRef.current,
      posOffset: false,
      index: 0,
    });
  }

  if (!wordTimestamps.current.length) {
    wordTimestamps.current = processJson2(audioInfo, wordTimestamps);
  }

  console.log("WORD LEVEL TIMESTAMPS: ", wordTimestamps.current);

  wordsIndexTracker.current = wordTimestamps.current.findIndex(
    (obj) => obj.start == startTime
  );

  if (wordIndexInString) {
    wordIndexInString.current = 0;
  }

  console.log("WORD TRACKER INDEX: ", wordsIndexTracker.current);

  var shadow = new fabric.Shadow({
    color: "#000",
    blur: 5,
  });

  // var wordIndex = wordsIndexTracker.current
  const wordIndex = wordsIndexTracker.current;
  var displayText = wordTimestamps.current[wordIndex]?.text.join(" ");

  const textbox = new CustomTextbox("", captionStyle);
  textbox.set("lockMovementX", true);
  textbox.set("lockMovementY", true);
  textbox.set("id", "cpt-txt");
  textbox.set("text", displayText);

  if (processType == playerModes.PREVIEW) {
    textRef.current[sceneInfo.id] = textbox;
  } else {
    textRef.current = textbox;
  }

  renderConfig.push({
    id: "m-txt",
    obj: textbox,
    posOffset: true,
    index: index++,
  });

  await renderObjectsFromConfig(
    renderConfig,
    containerParams,
    canvas,
    processType
  );

  await isVideoReady(sceneInfo.id, videoRef);
};

const processJson2 = (audioInfo, wordTimestamps) => {
  var phraseArray = [];
  var phraseStart = 0;
  var phraseEnd = 0;
  var wordCount = 0;

  var sentenceRangeMap = audioInfo.sentenceRangeMap;

  var words = audioInfo.wordLevelTimestamps;
  console.log("Words: ", words);
  var sentenceNumber = 0;
  var sentenceBoundary;
  var wordLevelInfo = [];

  for (let i = 0; i < words.length; i++) {
    var word = words[i];
    console.log("------------- x --------------");
    console.log("Index: ", i);
    console.log("Word: ", word);
    // offset = word.Offset / 10000000;
    // duration = word.Duration / 10000000;
    if (wordCount == 0) {
      phraseStart = word.wordStartTime;
    }

    sentenceBoundary =
      sentenceNumber < sentenceRangeMap.length
        ? sentenceRangeMap[sentenceNumber].end
        : 0;

    console.log("Sentence Number: ", sentenceNumber);
    console.log("Sentence Start: ", sentenceRangeMap[sentenceNumber].start);
    console.log("Sentence Boundary: ", sentenceBoundary);

    phraseArray.push(word.word);

    var wordStartIndex = phraseArray.join(" ").indexOf(word.word);
    var modifiedWord = {
      ...word,
      startIndex: wordStartIndex,
      endIndex: wordStartIndex + word.word.length,
    };

    wordLevelInfo.push(modifiedWord);
    console.log("Phrase Array: ", phraseArray);
    wordCount++;

    if (wordCount == 3 || word.wordEnd == sentenceBoundary) {
      phraseEnd = word.wordEndTime;
      wordTimestamps.current.push({
        start: phraseStart,
        end: phraseEnd,
        text: phraseArray,
        words: wordLevelInfo,
      });
      phraseArray = [];
      wordLevelInfo = [];
      wordCount = 0;
    }

    if (word.wordEnd == sentenceBoundary) {
      sentenceNumber++;
    }
  }
  console.log("Phrase Timestamp: ", wordTimestamps.current);
  return wordTimestamps.current;
};

function updateObjectCoords(objId, timeline, coords) {
  for (let i = 0; i < timeline.length; i++) {
    var obj = timeline[i];
    if (obj.id == objId) {
      var newObjectInfo = { ...obj, pos: coords };
      console.log("NEW OBJECT INFO: ", newObjectInfo);
      store.dispatch(updateObjectInfo({ id: objId, objInfo: newObjectInfo }));
    }
  }
}

export { playerModes, composeScene, updateObjectCoords };
