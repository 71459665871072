import { fabric } from "fabric";

const CustomTextbox = fabric.util.createClass(fabric.Textbox, {
  type: "textbox",

  toObject: function () {
    return fabric.util.object.extend(this.callSuper("toObject"), {
      backgroundColor: this.backgroundColor,
      padding: this.padding,
      borderRadius: this.borderRadius,
    });
  },

  initialize: function (text, options) {
    options || (options = {});
    this.callSuper("initialize", text, options);
    this.set({
      backgroundColor: options.backgroundColor || "",
      padding: options.padding || 0,
      borderRadius: options.borderRadius || 0,
    });
  },

  set: function (key, value) {
    if (typeof key === "object") {
      // If an object is passed, call set for each property
      for (var prop in key) {
        this.set(prop, key[prop]);
      }
    } else {
      // Custom logic before setting the property
      if (["text", "fontSize, fontFamily"].includes(key)) {
        console.log("Setting TEXt:", value);
        // this.set({ width: 300 });
        this._autoAdjustWidth(value);
      }

      // Call the parent class's set method
      this.callSuper("set", key, value);

      // Custom logic after setting the property (if needed)
      // For example, re-render the object
      this.canvas && this.canvas.renderAll();
    }

    return this;
  },

  _autoAdjustWidth: function (text) {
    // Create a temporary canvas context to measure text width
    var ctx = this.canvas
      ? this.canvas.getContext()
      : document.createElement("canvas").getContext("2d");
    ctx.font = this._getFontDeclaration(); // Use the current font style
    var textWidth = ctx.measureText(text).width;

    console.log("TEXT WIDTH: ", textWidth);

    // Set the new width considering padding
    var padding = this.padding || 0;
    this.set("width", textWidth + padding * 2);
  },

  _renderBackground: function (ctx) {
    if (!this.backgroundColor) {
      return;
    }

    ctx.fillStyle = this.backgroundColor;
    var w = this.width,
      h = this.height,
      r = Math.min(this.borderRadius, w / 2, h / 2),
      x = -w / 2,
      y = -h / 2;

    ctx.beginPath();
    // ctx.roundRect(10, 20, 150, 100, [40]);
    ctx.roundRect(
      -w / 2 - this.padding,
      -h / 2 - this.padding,
      w + this.padding * 2,
      h + this.padding * 2,
      r
    );
    ctx.fill();

    this.callSuper("_render", ctx);

    ctx.restore();
  },
});

export { CustomTextbox };
