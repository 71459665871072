import React, {useRef, useState, useEffect} from 'react'
import Dropdown from './Dropdown';
import { availableLanguages, voices, getLanguageCode } from '../constants/speechConfig';
import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import { VolumeUpTwoTone } from '@mui/icons-material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import StopCircleIcon from '@mui/icons-material/StopCircle';
import { useDispatch, useSelector } from 'react-redux'
import { setAudioInfo, setSceneInfo, setAlertDialogInfo } from '../redux/storySlice';
import { processTextToSpeech } from "../services/speech";


const AudioOptions = () => {
    const dispatch = useDispatch()

    const audioRef = useRef()
    const [isPlaying, setIsPlaying] = useState(false)

    const [language, setLanguage] = useState('')
    const [gender, setGender] = useState('')

    const [voiceSelected, setVoiceSelected] = useState()
    const [activeVoice, setActiveVoice] = useState()
    const [btnDisplay, setBtnDisplay] = useState();

    const [displayVoices, setDisplayVoices] = useState([])

    const [voiceOptions, setVoiceOptions] = useState()

    const audioInfo = useSelector(
        (state) => state.storyReducer.audioInfo
    )

    const storySettings = useSelector(
        (state) => state.storyReducer.settings
    )

    const genderOptions = [
        {value: 'All', label: 'All'},
        {value: 'Male', label: 'Male'},
        {value: 'Female', label: 'Female'}
    ]

    useEffect(() => {
        if(!voiceOptions && availableLanguages){
            var voiceList = []
            availableLanguages.map((lang) => {
                voiceList.push({label: lang, value: lang})
            })
            setVoiceOptions(voiceList)
        }
    }, [])

    useEffect(() => {
        console.log('LANG: ', language)
        console.log('GENDER: ', gender)
        var voiceList = voices.filter((obj) => {
            return language == obj.LocaleName && gender == obj.Gender;
          });
        setDisplayVoices(voiceList)
        console.log('DiSPLAY VOICES: ', voiceList)
    }, [language, gender])
    

    const handleClick=(e, voice)=>{
        var audioElm = audioRef.current
        if(isPlaying && activeVoice == voice.ShortName){
            setIsPlaying(false)
            audioElm.pause()
        }else{
          setIsPlaying(true)
          setActiveVoice(voice.ShortName)

          audioElm.src = `https://res.cloudinary.com/dlmsxlf2n/video/upload/v1723737009/Diveo/Assets/audio/voice/${voice.ShortName}.wav`;
          audioElm.volume = 0.5
          audioElm.play()
        }
    }

    const endMusic = (e) => {
        setIsPlaying(false)
    }

    const selectVoice = async (voice) => {
        console.log('GENERATING NEW VOICEOVER FOR : ', voice)
        dispatch(setAlertDialogInfo({info: {isOpen: true, source: 'audio-options'}}))
        setVoiceSelected(voice.ShortName)

        const projectId = storySettings.projectId;
        const text = audioInfo.text;
        const voiceCode = voice.ShortName;
        const langCode = getLanguageCode(language)

        console.log('Project ID: ', projectId);
        console.log('Text: ', text);
        console.log('Lang Code: ', langCode);

        console.log('Voice Selected: ', voiceSelected)

        const result = await processTextToSpeech(projectId, text, voiceCode, langCode);
        console.log('SPEECH PROCESSING COMPLETED!!')
        console.log('Result:: ', result);

        dispatch(setAudioInfo({ audioInfo: result.data }));
        dispatch(setAlertDialogInfo({info: {isOpen: false}}))
        dispatch(setSceneInfo({sceneInfo: []}))

    }

    const selectGender = (gender) => {
        console.log('GENDER SELECTED: ', gender)
        setGender(gender.value)
    }

    const selectLanguage = (lang) => {
        console.log('LANGUAGE SELECTED: ', lang)
        setLanguage(lang.value)
    }


    return (
        <div style={{height: '100%', padding: 10, overflow: 'scroll'}}>
            <audio ref={audioRef} onended={endMusic}></audio>

            <div style={{fontSize: 18,  fontWeight: 700, margin: 'auto', marginTop: 10, color: '#424242'}}>Select Voiceover </div>

            <div style={{marginTop: 30, marginBottom: 8, fontWeight: 700, color: '#424242'}}>Language</div>
            <div style={{ width: 250, marginRight: 5}}>
                <Dropdown
                    isSearchable={false}
                    menuSize={250}
                    placeHolder='Select Language'
                    options={voiceOptions}
                    onChange={(value) => selectLanguage(value)}
                />
            </div>
            
            <div style={{marginTop: 15, marginBottom: 8, fontWeight: 700, color: '#424242'}}>Gender</div>
            <div style={{ width: 250, marginRight: 5}}>
                <Dropdown
                    isSearchable={false}
                    menuSize={250}
                    placeHolder='Select Gender'
                    options={genderOptions}
                    onChange={(value) => selectGender(value)}
                />
            </div>

            <div style={{marginTop: 50, width: '95%'}}>
                {
                    displayVoices.map((voice) => 
                        <div style={{padding: 15, paddingInline:10, width: '100%', position: 'relative', margin: 'auto', marginBottom: 15, border: voiceSelected == voice.ShortName ? '2px solid #5b44ed': '1px solid #eee', borderRadius: 5}} onMouseEnter={() => setBtnDisplay(voice.ShortName)} onMouseLeave={() => setBtnDisplay(null)}>
                            <div style={{display: 'inline-block',  color: voiceSelected == voice.ShortName ? '#5b44ed': '#000'}}>  
                                <span style={{cursor: 'pointer'}}  onClick={(e)=>handleClick(e, voice)}>
                                    {isPlaying && activeVoice == voice.ShortName ? 
                                        <StopCircleIcon style={{ fontSize: 35}} /> : 
                                        <PlayCircleOutlineIcon style={{ fontSize: 35}}/>
                                    }
                                </span>
                                <span style={{marginLeft: 20, color: '#424242'}}>{voice.DisplayName} </span> 
                           </div>

                           {
                               voice.ShortName == btnDisplay &&
                                <div style={{ position: 'absolute', right: 10, cursor: 'pointer', display: 'inline-block', marginLeft: 30, fontSize: 14, borderRadius: 8, padding: 3, paddingInline: 6}} onClick={()=>selectVoice(voice)}>
                                    {
                                        voice.ShortName != voiceSelected && <span><AddCircleOutlineIcon style={{ color: '#5b44ed', fontSize: 25}}/></span>
                                    }
                                </div>
                           }
                           {
                                voice.ShortName == voiceSelected &&  
                                <div style={{ position: 'absolute', right: 10, cursor: 'pointer', display: 'inline-block', marginLeft: 30, fontSize: 14, borderRadius: 8, padding: 3, paddingInline: 6}}>
                                    <span><CheckCircleIcon style={{color: '#20bf55', fontSize: 25}}/></span>
                                </div>
                            }
                        </div>
                    )
                }
            </div>
            
            
        </div>
    )
}

export default AudioOptions
