import React, {useState, useEffect, useRef} from 'react'
import { fabric } from "fabric";
import { store } from '../redux/store';
import {
    getPreviewContainerDimensions,
  } from "../constants/canvasSettings";
import { data2 } from "../constants/data";
import { playerModes, composeScene } from "../utils/sceneUtils";
import { renderFactory } from "../utils/renderUtils";


var data3 = data2.data.sentenceRangeMap;

const RenderControl = () => {

    const canvasRef = useRef()
    const fabricRef = useRef()
    const clipPathRef = useRef(null);

    const renderRef = useRef()

    const inputRef = useRef()
    
    const sceneFrameInfoMap = useRef()
    const currentSceneFrameInfo = useRef()

    const currentSceneRef = useRef()

    const wordTimestamps = useRef([])
    const wordsIndexTracker = useRef()
    const wordIndexInString = useRef()

    const [scenes, setScenes] = useState([])
    const [isSceneReady, setIsSceneReady] = useState(true)

    const [timelineInfo, setTimelineInfo] = useState()
    const [audioInfo, setAudioInfo] = useState()

    const [captionStyle, setCaptionStyle] = useState()

    const fps = 30;

    const [containerParams, setContainerParams] = useState()
    
    const videoRef = useRef([])
    const textRef = useRef();
    const [quality, setQuality] = useState(480)

    const [previewContext, setPreviewContext] = useState(null)

    useEffect(() => {
        if (previewContext){
          
            console.log('PREVIEW CONTEXT SET')
            var aspectRatio = previewContext['settings']?.aspectRatio ?? '16/9'
            var quality = previewContext['settings']?.quality ?? 720
            // setTextBlock(aspectRatio)
            setQuality(quality)
            setFrameController()
            setTimelineInfo(previewContext['timeline'])
            setAudioInfo(previewContext['audioInfo'])   
            setScenes(previewContext['sceneInfo'])

            setCaptionStyle(previewContext['captionStyle'])

            const containerSettings = getPreviewContainerDimensions(aspectRatio)
            setContainerParams(containerSettings)
            console.log("Container Params: ", containerSettings)
            console.log('Previee: ', previewContext)
        }
       
    }, [previewContext])


    // DEBUG
    // useEffect(() => {
    //     const state = store.getState();
    //     // URL.revokeObjectURL('s')
    //     setPreviewContext({
    //         timeline: state.storyReducer.timelineInfo, 
    //         captionStyle: state.storyReducer.captionStyle,
    //         sceneInfo: state.storyReducer.sceneInfoArray, 
    //         audioInfo: state.storyReducer.audioInfo, 
    //         settings: state.storyReducer.settings
    //     })
    // }, [])

    useEffect(() => {
        if(isSceneReady){
            window.isSceneReady = true
        }
    }, [isSceneReady])

    useEffect(() => {
        // if (!canvasRef.current) return;
        // if (fabricRef.current) return;
        if (containerParams && scenes) {
            console.log('Setting Up Stage')
            setSceneFrameBounds()
            const canvas = new fabric.Canvas('canvas', {
                width: containerParams.width,
                height: containerParams.height,
                preserveObjectStacking: true,
                backgroundColor: "#f5f5f5",
            });

            fabricRef.current = canvas;
    
            // Set total scene count
            console.log('Setting up stage')
            // setCurrentScene(scenes[0])
            window.readyForRendering = true
        }
    
    }, [containerParams, scenes]);


    const setSceneFrameBounds = () => {
        var sceneFrameBounds ={}

        scenes.map((scene, index) => {
            var frameStart = index == 0 ? 0 : sceneFrameBounds[index-1][1] + 1
            var frameEnd = Math.floor(scene.end * fps) 
            sceneFrameBounds[index] = [frameStart, frameEnd]
        })
        sceneFrameInfoMap.current = sceneFrameBounds;
        console.log('SCENE FRAMES MAP: ', sceneFrameBounds)
    }

    
    const setFrameController = () => {
        window.setFrame = (frameNum) => {
            window.ready = false
            window.frameNumber = frameNum
        }    
    }

    const isVideoReady = () => {
        return new Promise(resolve => {
            const sceneId = currentSceneRef.current.id
            if(videoRef.current[sceneId]){
                var handle = setInterval(() => {
                    var video = videoRef.current[sceneId]
                    if( video.readyState == 4){
                        console.log('State ==>', video.readyState)
                        clearInterval(handle);
                        console.log('VIDEO READY!!')
                        resolve()
                    }
                }, 100);
              
            }else{
                resolve()
            }
        });
    }

    const getCurrentSceneId = (frameNum) => {
        console.log('Timeline: ',  sceneFrameInfoMap.current)
        console.log('Frame Num: ', frameNum)
        for(let i=0 ; i< Object.keys(sceneFrameInfoMap.current).length ; i++){
            if(frameNum >=  sceneFrameInfoMap.current[i][0] && frameNum <=  sceneFrameInfoMap.current[i][1])
                return i;
        }

    }

    const updateSceneInControlledRenderer = (frame) => {
        return new Promise(async (resolve, reject) => {
            console.log('CURRENT SCENE FRAME INFO: ', currentSceneFrameInfo.current)
            if(currentSceneFrameInfo.current && frame >= currentSceneFrameInfo.current[0] && frame < currentSceneFrameInfo.current[1]){
                console.log('=====> 1')
                resolve()
            }else{
                console.log('=====> 2')
                setIsSceneReady(false)

                var canvas = fabricRef.current
                var sceneID = getCurrentSceneId(frame)
                console.log('SCENE ID: ', sceneID)
                currentSceneRef.current = scenes[sceneID]

                canvas.remove(...canvas.getObjects().concat())
                
                canvas.setZoom(1)
                canvas.setWidth(containerParams.viewWidth);
                canvas.setHeight(containerParams.viewHeight);

                console.log('Current Scene ID: ')

                // Compose Scene Here

                await composeScene(
                    currentSceneRef.current,
                    audioInfo,
                    timelineInfo,
                    fabricRef.current,
                    containerParams,
                    clipPathRef,
                    videoRef,
                    textRef,
                    captionStyle,
                    wordTimestamps,
                    wordsIndexTracker,
                    wordIndexInString,
                    playerModes.RENDER_CONTROL
                )

                await isVideoReady()

                setIsSceneReady(true)
                console.log('SCENE READY!!')

                // DEBUG
                setZoom()

                var totalTimeElasped = Math.min(parseInt(frame) * (1/fps))
                wordsIndexTracker.current = wordTimestamps.current?.findIndex(obj => obj.start >= totalTimeElasped) ?? 0
                wordIndexInString.current = getCurrentWordIndexInString(totalTimeElasped);

                const renderer = renderFactory(  
                    currentSceneRef.current,
                    null,
                    null,
                    fabricRef,
                    clipPathRef,
                    null,
                    textRef,
                    captionStyle,
                    null,
                    wordTimestamps,
                    wordsIndexTracker,
                    wordIndexInString,
                    null,
                    playerModes.RENDER_CONTROL
                )

                renderRef.current = renderer

                // Set CurrentSceneTimeline
                currentSceneFrameInfo.current = sceneFrameInfoMap.current[sceneID]

                console.log('Timeline: ', sceneFrameInfoMap.current)
                console.log(currentSceneFrameInfo.current)
                
                // await window.waiting.promise
                resolve()
            }
        });
    }

    function getCurrentWordIndexInString(currentTime){
        console.log('CURRENT TIME: ', currentTime)

        var wordIndex = wordTimestamps.current.findIndex(obj => obj.start <= currentTime && obj.end > currentTime)
        wordIndex = wordIndex == -1 ? 0 : wordIndex; 
        wordsIndexTracker.current = wordIndex;
        console.log('WORD IDX: ', wordIndex)
        var currentStr = wordTimestamps.current[wordIndex];
        if(!currentStr){
            return 0;
        }
        var currentStrArr = currentStr.text;
        console.log('CURRENT STR ARRAY: ', currentStrArr)

        var words = audioInfo.wordLevelTimestamps;
        var currentWordObj = words?.find(obj => obj.wordStartTime <= currentTime && obj.wordEndTime > currentTime)

        if(currentWordObj){
            const currentWord = currentWordObj.word;
            console.log('CURRENT WORD: ', currentWord)

            const currentWordIdxInString = currentStrArr.indexOf(currentWord)
            console.log('CURRENT WORD INDEX IN STRING: ', currentWordIdxInString)
            return currentWordIdxInString;

        }

        return 0;
        
    }


    function inRange(x, min, max) {
        return (x - min) * (x - max) <= 0;
    }


    const seekToFrame = async () => {
        
        window.ready = false
        window.frameReady = false

        // DEBUG
        // if(!window.frameNumber){
        //     window.frameNumber = 1
        // }
        // window.frameNumber = window.frameNumber + 1
        // renderFrameOnCanvas()

        var frame = window.frameNumber ?? 0

        console.log('Frame Number: ', frame)

        console.log('Current Timeline: ', currentSceneFrameInfo.current)
        await updateSceneInControlledRenderer(frame)
        
        console.log('Scene COmposed')
        var vframe = Math.max(frame - currentSceneFrameInfo.current[0], 1)
        var progressTime = (1 / fps) * vframe 
        console.log('Progress Time: ', progressTime)

        var totalTimeElasped = Math.min(frame * (1/fps))

        var objId = currentSceneRef.current.id
        console.log('OBJ ID: ', objId)

        renderRef.current.renderCaptions(totalTimeElasped)

        const videoEl = videoRef.current[objId]
        if(videoEl){
            console.log('VIDEO REF: ', videoRef.current)
            console.log('VIDEO: ', videoEl)
            
            videoEl.currentTime = progressTime + 0.001
            await videoEl.play()
            window.ready = false
            await videoEl.pause()
            videoEl.currentTime = progressTime + 0.001
            window.frameReady = true
        }
    }

    const renderFrameOnCanvas = () => {
        console.log('CANVAS: ', fabricRef.current)
        fabricRef.current.renderAll()
    }


    const loadPreviewContext = () => {
        var context = window.previewContextObj
        setPreviewContext(context)
    }

    const setFrameNum = () => {
        var value = inputRef.current.value
        console.log('Updating Frame Number:', value)
        window.frameNumber = parseInt(value)
        currentSceneFrameInfo.current = null;

    }

    const setZoom = () => {
        var canvas = fabricRef.current
        canvas.setZoom(quality/containerParams.viewHeight)
        canvas.setWidth(containerParams.viewWidth * canvas.getZoom());
        canvas.setHeight(containerParams.viewHeight * canvas.getZoom());
    }



    return (
        <>  
            <div>
                <canvas id="canvas" ref={canvasRef}>

                </canvas>
                <button id='seek-frame' class="btn btn-secondary" style={{margin: 20}} onClick={() => seekToFrame()}>Seek Frame</button>
                
                
                <button id='render-frame' onClick={renderFrameOnCanvas} style={{margin: 50}}>Render</button>
                <button id='load-preview-context' onClick={loadPreviewContext} style={{margin: 50}}>Load Context</button>

                <div>
                    <input ref={inputRef} type='text' name='input'></input>
                    <button onClick={setFrameNum}>Update Frame</button>
                </div>
                {/* <p>
                    {textBlock}
                </p> */}

            <button id='zoom' onClick={setZoom} style={{margin: 50}}>Zoom</button>
                
            </div>
        </>
    )
}

export default RenderControl
