import React, { useState, useRef, useEffect, useCallback } from "react";
import { fabric } from "fabric";
import {
  getPreviewContainerDimensions,
} from "../constants/canvasSettings";
import { data, data1, data2 } from "../constants/data";

var data3;

let audioContext,
  audioSource,
  currentTime,
  startTime,
  timelineDuration = 39.47,
  wordIndex = 0,
  phraseTimestamp,
  objectsToBeRemoved;

let animationFrameId;
let currentActiveObjects = [];

// Array to store the objects to be rendered
let objectsToRender = [];

// Array to store the rendered objects
let renderedObjects = [];

// Array to store the objects being prepared

let preparedObjects = [];

const Anime = () => {
  const canvasRef = useRef();
  const fabricRef = useRef();

  const videoRef = useRef([]);
  const textRef = useRef();

  const [containerParams, setContainerParams] = useState();

  useEffect(() => {
    const containerSettings = getPreviewContainerDimensions(16 / 9);
    setContainerParams(containerSettings);
    console.log("VIDOE REFS: ", videoRef);
  }, []);

  useEffect(() => {
    // if (!canvasRef.current) return;
    // if (fabricRef.current) return;
    if (containerParams) {
      const canvas = new fabric.Canvas("canvas", {
        width: 600,
        height: 405,
        preserveObjectStacking: true,
        selection: false,
        backgroundColor: "#212529",
      });

      fabric.Object.prototype.transparentCorners = false;
      fabric.Object.prototype.cornerColor = "white";
      fabric.Object.prototype.cornerStrokeColor = "#000";
      fabric.Object.prototype.cornerSize = 10;
      fabric.Object.prototype.borderColor = "#7CB9E8";
      fabric.Object.prototype.borderScaleFactor = 1;
      fabric.Object.prototype.cornerStyle = "circle";
      fabric.Object.prototype.setControlsVisibility({ mtr: false });

      fabricRef.current = canvas;

      // composeScene1(canvas)
      // composeScene(canvas);
    }

    // composeImage(canvas)

    // decompileTemplateConfig(0, canvas, {text: 'The most durable glass and powerful battery'})
  }, [containerParams]);

  function loadAudio() {
    audioContext = new AudioContext();
    const request = new XMLHttpRequest();
    request.open(
      "GET",
      "https://res.cloudinary.com/dlmsxlf2n/video/upload/v1718640312/Diveo/Assets/audio/voiceover-ad3.wav",
      true
    );
    request.responseType = "arraybuffer";

    request.onload = function () {
      console.log("Request Response: ", request.response);
      audioContext.decodeAudioData(request.response, function (buffer) {
        audioSource = audioContext.createBufferSource();
        audioSource.buffer = buffer;
        audioSource.connect(audioContext.destination);
      });
    };

    request.send();
  }

  const createVideoElement = (videoInfo) => {
    var video = document.createElement("video");
    video.crossorigin = "anonymous";
    video.height = videoInfo.height;
    video.width = videoInfo.width;
    video.src = videoInfo.url;

    return video;
  };

  function inRange(x, min, max) {
    return (x - min) * (x - max) <= 0;
  }

  const processJson2 = () => {
    var phraseTimestamp = [];
    var phraseArray = [];
    var phraseStart = 0;
    var phraseEnd = 0;
    var wordCount = 0;

    var offset;
    var duration;

    var sentenceRangeMap = data2.data.sentenceRangeMap;
    data3 = sentenceRangeMap;
    console.log("DATA3: ", data3);

    var words = data2.data.wordLevelTimestamps;
    console.log("Words: ", words);
    var sentenceNumber = 0;
    var sentenceBoundary;

    for (let i = 0; i < words.length; i++) {
      var word = words[i];
      console.log("------------- x --------------");
      console.log("Index: ", i);
      console.log("Word: ", word);
      // offset = word.Offset / 10000000;
      // duration = word.Duration / 10000000;
      if (wordCount == 0) {
        phraseStart = word.wordStartTime;
      }

      sentenceBoundary =
        sentenceNumber < sentenceRangeMap.length
          ? sentenceRangeMap[sentenceNumber].end
          : 0;

      console.log("Sentence Number: ", sentenceNumber);
      console.log("Sentence Start: ", sentenceRangeMap[sentenceNumber].start);
      console.log("Sentence Boundary: ", sentenceBoundary);

      phraseArray.push(word.word);
      console.log("Phrase Array: ", phraseArray);
      wordCount++;

      if (wordCount == 3 || word.wordEnd == sentenceBoundary) {
        phraseEnd = word.wordEndTime;
        phraseTimestamp.push({
          start: phraseStart,
          end: phraseEnd,
          words: phraseArray,
        });
        phraseArray = [];
        wordCount = 0;
      }

      if (word.wordEnd == sentenceBoundary) {
        sentenceNumber++;
      }
    }
    console.log("Phrase Timestamp: ", phraseTimestamp);
    return phraseTimestamp;
  };

  const preloadObject = (obj) => {
    let preloadedObj;
    var canvas = fabricRef.current;
    switch (obj.type) {
      case "video":
        console.log("PRELOAD OBJ: ", obj);
        var mediaEl = createVideoElement(obj.info);
        console.log(mediaEl);

        var image = new fabric.Image(mediaEl, {
          left: 0,
          top: 0,
          scaleX: 405 / obj.info.height,
          scaleY: 405 / obj.info.height,
          selectable: true,
          hasControls: false,
        });

        canvas.add(image);
        image.sendToBack();

        console.log("MEDIA EL: ", mediaEl);
        videoRef.current[obj.id] = mediaEl;
        renderedObjects[obj.id] = image;

        canvas.requestRenderAll();

        break;
    }
    preparedObjects.push(obj);
  };

  const renderObject = (obj) => {
    let renderedObj;
  };

  const render = (t1) => {
    const time = t1 / 1000;

    const elapsedTime = time - startTime;
    currentTime = elapsedTime;

    console.log("CURRENT TIME: ", currentTime);

    // Get Objects to Render
    objectsToRender = data3.filter((obj) => {
      return currentTime >= obj.startTime && currentTime < obj.endTime;
    });

    console.log("OBJS TO RENDER: ", objectsToRender);

    // Filter the objects to be rendered based on their start and end times
    objectsToBeRemoved = data3.filter((obj) => {
      return currentTime >= obj.endTime;
    });

    console.log("OBJS TO BE REMOVED: ", objectsToBeRemoved);

    // Prepare objects that need to be loaded before rendering
    data3.forEach((obj) => {
      if (currentTime >= obj.startTime - 5 && !preparedObjects.includes(obj)) {
        // preparedObjects.push(obj);
        preloadObject(obj);
      }
    });

    console.log("MEDIA : ", videoRef.current);

    // Render the Objects in the 
    objectsToRender.forEach((obj) => {
      if (!currentActiveObjects.includes(obj)) {
        if (obj.type == "video") {
          console.log("PLAYING VIDEO");
          videoRef.current[obj.id].play();
          fabricRef.current.requestRenderAll();
        }
        currentActiveObjects.push(obj);
      }
    });

    console.log("CURRENT ACTIVE OBJS: ", currentActiveObjects);

    // Remove the objects after end time
    objectsToBeRemoved.forEach((obj) => {
      if (currentActiveObjects.includes(obj)) {
        const renderedObj = renderedObjects[obj.id];
        fabricRef.current.remove(renderedObj);

        const index = currentActiveObjects.indexOf(obj);
        if (index !== -1) {
          currentActiveObjects.splice(index, 1);
        }
      }
    });

    // Render Captions
    if (
      inRange(
        currentTime,
        phraseTimestamp[wordIndex].start,
        phraseTimestamp[wordIndex].end
      )
    ) {
      var phrase = phraseTimestamp[wordIndex].words.join(" ");
      textRef.current.set("text", phrase);
      wordIndex++;
    }

    fabricRef.current.requestRenderAll();
    animationFrameId = requestAnimationFrame(render);

    // Stop the animation when the timeline ends
    if (currentTime >= timelineDuration) {
      cancelAnimationFrame(animationFrameId);
    }
  };

  const isVideoReady = (videoRef) => {
    return new Promise((resolve) => {
      if (videoRef.current) {
        var handle = setInterval(() => {
          var video = videoRef.current;
          if (video.readyState == 4) {
            console.log("State ==>", video.readyState);
            clearInterval(handle);
            console.log("VIDEO READY!!");
            resolve();
          }
        }, 100);
      } else {
        resolve();
      }
    });
  };

  function startAnimation() {
    loadAudio();
    phraseTimestamp = processJson2();
    startTime = performance.now() / 1000; // Get the start time in seconds
    data3.forEach((obj) => {
      if (obj.start == 0) {
        // preparedObjects.push(obj);
        preloadObject(obj);
      }
    });

    // audioSource = audioContext.createBufferSource();
    // audioSource.buffer = audioBuffer;
    // audioSource.connect(audioContext.destination);

    var shadow = new fabric.Shadow({
      color: "#000",
      blur: 5,
    });

    var textOptions = {
      fontSize: 30,
      fontWeight: 800,
      fontFamily: "Arial",
      fill: "yellow",
      // backgroundColor: "black",
      textAlign: "center",
      scaleX: 1,
      scaleY: 1,
      top: 300,
      left: 200,
      width: 300,
      shadow: shadow,
    };

    var textbox = new fabric.Textbox("", textOptions);

    fabricRef.current.add(textbox);
    textbox.bringToFront();
    fabricRef.current.requestRenderAll();

    textRef.current = textbox;

    wordIndex = 0;

    audioSource.start(0);

    // cancelAnimationFrame(animationFrameId);
    animationFrameId = requestAnimationFrame(render);
  }

  return (
    <div>
      {containerParams && (
        <div>
          <div
            style={{
              height: 405,
              width: 600,
              margin: "0 auto",
              background: "1px solid black",
            }}
          >
            <canvas
              id="canvas"
              ref={canvasRef}
              style={{ height: 405, width: 600 }}
            ></canvas>
          </div>
          <div
            style={{
              margin: "auto",
              width: containerParams.width,
              height: 50,
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <div
              style={{
                display: "inline-block",
                margin: "auto",
                cursor: "pointer",
              }}
              onClick={startAnimation}
            >
              {
                <span
                  style={{ color: "#000", fontSize: 45 }}
                ><i class="bi bi-play-fill"></i></span>
              }
            </div>
            {/* <div style={{ display: 'inline-block', margin: 'auto', cursor: 'pointer'}} onClick={seekToFrame} >
              {
                  <SkipNextIcon style={{color: '#fff', fontSize: 45}}></SkipNextIcon>
              } 
            </div> */}
          </div>
          <div>
            <button onClick={loadAudio}> Load</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Anime;
