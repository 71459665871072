import axios from "axios";

async function processTextToSpeech(projectId, text, voice, langCode) {
  console.log(
    `Calling text to speech API with Text: ${text} and Voice: ${voice}`
  );
  var data = {
    projectId,
    text,
    voice,
    langCode,
  };
  try {
    // Server Endpoint = http://3.112.42.224:80/speech
    let response = await axios.post(`http://localhost:8000/speech`, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    let result = response.data;
    console.log("Response: ", result);
    return result;
  } catch (error) {
    console.log("Error Occurred");
    console.log(error);
    return { message: `Error: ${error}` };
  }
}

export { processTextToSpeech };
