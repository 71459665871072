import { fabric } from "fabric";
import { playerModes } from "./sceneUtils";

function createVideoElement(videoInfo) {
  console.log("VIDEO INFO ===>", videoInfo);
  var video = document.createElement("video");
  if (videoInfo) {
    video.crossOrigin = "anonymous";
    video.muted = true;
    video.height = videoInfo.height;
    video.width = videoInfo.width;
    video.src = videoInfo.url;
  }

  video.onseeked = updateStateAfterSeeking;

  return video;
}

const updateStateAfterSeeking = () => {
  console.log("SEEKING FRAME FOR VIDEO");
  window.ready = true;
};

const isVideoReady = (videoRef, objId) => {
  return new Promise((resolve) => {
    const video = videoRef.current[objId];
    if (video) {
      var handle = setInterval(() => {
        // var video = videoRef.current;
        if (video.readyState == 4) {
          console.log("State ==>", video.readyState);
          clearInterval(handle);
          console.log("VIDEO READY!!");
          resolve();
        }
      }, 100);
    } else {
      resolve();
    }
  });
};

function adjustRenderOffset(renderOffset, containerParams, obj) {
  if (renderOffset) {
    obj.set("left", obj.left - containerParams.viewX);
    obj.set("top", obj.top - containerParams.viewY);
  }
}

async function addObjectToCanvas(
  sceneId,
  obj,
  canvas,
  containerParams,
  videoRef,
  clipPathRef,
  renderConfig,
  index,
  createMode
) {
  switch (obj.type) {
    case "video":
      const image = await addOrReplaceVideo(
        obj.id,
        obj,
        sceneId,
        canvas,
        containerParams,
        videoRef,
        clipPathRef,
        createMode
      );
      // renderedObjects[obj.id] = image;
      return image;
  }
}

async function addOrReplaceVideo(
  objId,
  objInfo,
  sceneId,
  canvas,
  containerParams,
  videoRef,
  clipPathRef,
  isCreated
) {
  console.log("IS CREATED: ", isCreated);
  var image;
  var mediaEl;

  var videoInfo = objInfo.info;
  console.log("OBJ INFO: ", objInfo);
  console.log("VIDEO INFO: ", videoInfo);

  console.log("CLIP PATH ==> ", clipPathRef);
  var clipPath = clipPathRef ? clipPathRef.current : null;

  if (isCreated) {
    mediaEl = createVideoElement(videoInfo);
    console.log(mediaEl);

    image = new fabric.Image(mediaEl, {
      id: objId,
      scaleX: containerParams.viewHeight / videoInfo.height,
      scaleY: containerParams.viewHeight / videoInfo.height,
      sceneId: sceneId,
    });

    if (clipPath) {
      image.set("clipPath", clipPath);
    }

    if (objInfo.pos) {
      image.set("left", objInfo.pos.x);
      image.set("top", objInfo.pos.y);
    } else {
      var centerPoint;
      if (clipPath) {
        centerPoint = clipPath.getCenterPoint();
        console.log("CENTER POINT ===> ", centerPoint);
      } else {
        var canvasCenter = canvas.getCenter();
        centerPoint = new fabric.Point(canvasCenter.left, canvasCenter.top);
        console.log("CENTER POINT ===> ", centerPoint);
      }

      image.setPositionByOrigin(centerPoint, "center", "center");
    }

    // canvas.add(image);
    // image.sendToBack();

    videoRef.current[objId] = mediaEl;

    mediaEl.currentTime = 0.001;
    await mediaEl.play();
    await mediaEl.pause();

    // canvas.requestRenderAll();
  } else {
    // Video Replace Logic

    const canvasObjs = canvas.getObjects();
    var targetObj;
    var objIndex;

    for (let i = 0; i < canvasObjs.length; i++) {
      var obj = canvasObjs[i];
      if (obj.id == objId) {
        targetObj = obj;
        objIndex = i;
        break;
      }
    }

    if (targetObj) {
      console.log("OBJECT INDEX: ", objIndex);

      mediaEl = createVideoElement(videoInfo);
      console.log(mediaEl);

      image = new fabric.Image(mediaEl, {
        id: objId,
        top: containerParams.viewY,
        scaleX: containerParams.viewHeight / videoInfo.height,
        scaleY: containerParams.viewHeight / videoInfo.height,
        sceneId: sceneId,
        selectable: true,
      });

      image.set("clipPath", clipPath);

      if (objInfo.pos) {
        image.set("left", objInfo.pos.x);
        image.set("top", objInfo.pos.y);
      } else {
        var centerPoint = clipPath.getCenterPoint();
        image.setPositionByOrigin(centerPoint, "center", "center");
      }

      canvas.remove(targetObj);
      canvas.insertAt(image, objIndex);

      console.log("REPLACE VIDEO OBJ_ID:", objId);
      videoRef.current[objId] = mediaEl;
      console.log("VIDEOS:", videoRef.current);

      mediaEl.currentTime = 0.001;
      await mediaEl.play();
      await mediaEl.pause();

      // canvas.requestRenderAll();
    }
  }

  //   await isVideoReady(videoRef, objId);

  return image;
}

async function addObjectsFromTimeline(
  timeline,
  sceneId,
  startTime,
  containerParams,
  canvas,
  videoRef,
  clipPathRef,
  index,
  renderConfig
) {
  const timelineInfo = [...timeline];
  for (let i = 0; i < timelineInfo.length; i++) {
    var obj = timelineInfo[i];
    if (obj.startTime == startTime) {
      var renderedObj = await addObjectToCanvas(
        sceneId,
        obj,
        canvas,
        containerParams,
        videoRef,
        clipPathRef,
        renderConfig,
        index,
        true
      );
      renderConfig.push({
        id: obj.id,
        obj: renderedObj,
        posOffset: obj.pos ? true : false,
        index: index,
      });
    }
  }
}

function renderObjectsFromConfig(
  renderConfig,
  containerParams,
  canvas,
  processType
) {
  return new Promise((res, rej) => {
    console.log("Render Config::=> ", renderConfig);

    renderConfig.sort(function (a, b) {
      return b.index - a.index;
    });
    console.log("ORDERED RENDER CONFIG: ", renderConfig);

    renderConfig.map((config) => {
      // ADJUST RENDER OFFSET
      if (processType != playerModes.PLAYER) {
        adjustRenderOffset(config.posOffset, containerParams, config.obj);
      }

      canvas.add(config.obj);
      config.obj.sendToBack();
    });

    canvas.requestRenderAll();
    res();
  });
}

function renderFactory(
  sceneInfo,
  renderInitTime,
  timeline,
  fabricRef,
  clipPathRef,
  videoRef,
  textRef,
  captionStyle,
  audioSourceRef,
  wordTimestamps,
  wordsIndexTracker,
  wordIndexInString,
  waitLockRef,
  processType
) {
  let animationFrameId;
  let objectsToRender = [];
  let currentActiveObjects = [];

  function inRange(x, min, max) {
    return (x - min) * (x - max) <= 0;
  }

  const render = (t1) => {
    const time = t1 / 1000;

    const startTime = sceneInfo.start;
    const endTime = sceneInfo.end - 0.04;

    const elapsedTime = time - renderInitTime.current;
    var currentTime = elapsedTime + startTime;

    console.log("CURRENT TIME: ", currentTime);

    // Get Objects to Render
    objectsToRender = timeline.filter((obj) => {
      return currentTime >= obj.startTime && currentTime < obj.endTime;
    });

    console.log("OBJS TO RENDER: ", objectsToRender);

    // Prepare objects that need to be loaded before rendering
    // data3.forEach((obj) => {
    //   if (currentTime >= obj.startTime - 5 && !preparedObjects.includes(obj)) {
    //     // preparedObjects.push(obj);
    //     preloadObject(obj);
    //   }
    // });

    console.log("MEDIA : ", videoRef.current);

    // Render the Objects in the
    objectsToRender.forEach((obj) => {
      if (!currentActiveObjects.includes(obj)) {
        if (obj.type == "video") {
          console.log("PLAYING VIDEO");
          videoRef.current[obj.id]?.play();
          fabricRef.current.requestRenderAll();
        }
        currentActiveObjects.push(obj);
      }
    });

    console.log("CURRENT ACTIVE OBJS: ", currentActiveObjects);

    renderCaptionsAtTime(currentTime);

    fabricRef.current.requestRenderAll();
    animationFrameId = requestAnimationFrame(render);

    // Stop the animation when the timeline ends
    if (currentTime >= endTime) {
      if (waitLockRef && waitLockRef.current) {
        waitLockRef.current.resolve();
      }
      audioSourceRef.current.stop();

      cancelAnimationFrame(animationFrameId);
      animationFrameId = null;
    }
  };

  function renderCaptionsAtTime(currentTime) {
    var wordIndex = wordsIndexTracker.current;
    console.log("Rendering captions at time: ", currentTime);
    console.log("Word Index: ", wordIndex);
    var captionText;
    if (processType == playerModes.PREVIEW) {
      captionText = textRef.current[sceneInfo.id];
    } else {
      captionText = textRef.current;
    }

    // Render Captions
    if (
      inRange(
        currentTime,
        wordTimestamps.current[wordIndex]?.start,
        wordTimestamps.current[wordIndex]?.end
      )
    ) {
      var phrase = wordTimestamps.current[wordIndex].text.join(" ");
      if (captionText.text != phrase) {
        // console.log('')

        // captionText.removeStyle("textBackgroundColor");
        if (captionStyle.animWord) {
          Object.keys(captionStyle.animWord).map((prop) => {
            captionText.removeStyle(prop);
            console.log("TEXT: ", captionText.text);
            console.log("PROP: ", prop);
          });
        }
        captionText.set("text", phrase);
        // captionText.removeStyle("fill");
      }

      wordsIndexTracker.current = wordIndex + 1;
    }

    if (captionStyle.animWord) {
      applyStylesAtWordLevel(wordIndex, currentTime, captionText);
    }
  }

  function applyStylesAtWordLevel(wordIndex, currentTime, captionText) {
    console.log(
      `Word Level Animations For Cations:\n time: ${currentTime} \n wordIndex: ${wordIndex}`
    );
    wordIndex = wordsIndexTracker.current;
    var indexInString = wordIndexInString.current;
    var currentWord =
      wordTimestamps.current[wordIndex - 1]?.words[indexInString];
    // console.log(' WORD INDEX IN STRING: ', indexInString)
    console.log("RENDER: CURRENT WORD = ", currentWord);
    if (
      currentWord &&
      inRange(currentTime, currentWord.wordStartTime, currentWord.wordEndTime)
    ) {
      // console.log('CURRENT WORD:', currentWord)
      // console.log(' WORD INDEX IN STRING: ', indexInString)
      var stringArr = wordTimestamps.current[wordIndex - 1].text;
      // console.log('STRING ARRAY: ', stringArr)

      // captionText.removeStyle("textBackgroundColor");
      // captionText.setSelectionStyles(
      //   { textBackgroundColor: "#000" },
      //   currentWord.startIndex,
      //   currentWord.endIndex
      // );

      // captionText.setSelectionStyles(
      //   { fill: "#000" },
      //   currentWord.startIndex,
      //   currentWord.endIndex
      // );

      if (captionStyle.restoreState && captionStyle.animWord) {
        Object.keys(captionStyle.animWord).map((prop) => {
          captionText.removeStyle(prop);
          console.log("TEXT: ", captionText.text);
          console.log("PROP: ", prop);
        });
      }

      captionText.setSelectionStyles(
        captionStyle.animWord,
        currentWord.startIndex,
        currentWord.endIndex
      );
      indexInString++;

      wordIndexInString.current = indexInString;
      if (stringArr.indexOf(currentWord.word) == stringArr.length - 1) {
        wordIndexInString.current = 0;
      }
    }
  }

  return {
    start() {
      animationFrameId = requestAnimationFrame(render);
      return animationFrameId;
    },
    stop() {
      if (animationFrameId) {
        cancelAnimationFrame(animationFrameId);
        animationFrameId = null;
      }
    },
    renderCaptions(t1) {
      return renderCaptionsAtTime(t1);
    },
  };
}

export {
  addOrReplaceVideo,
  addObjectToCanvas,
  addObjectsFromTimeline,
  renderObjectsFromConfig,
  renderFactory,
};
