import React, {useEffect, useState, useCallback} from 'react'
import { getAllProjectsForUser } from '../services/firebaseStore'
import { useIsAuthenticated } from '../hooks/useIsAuthenticated';
import { useDispatch, useSelector } from 'react-redux'
import { clearAndApplyNewProject } from '../redux/storySlice';
import { useNavigate } from "react-router-dom";
import MenuBar from './MenuBar';

const ProjectView = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const authUser = useIsAuthenticated()
    const [projects, setProjects] = useState([])

    const fetchProjects = useCallback(async () => {
        var projectsList = await getAllProjectsForUser()
        console.log('Projects: ', projectsList)
        setProjects(projectsList)
      }, [authUser])

    useEffect(() => {
        fetchProjects();
    }, [fetchProjects])

    const openProject = async (index) => {
        var project = projects[index]
        const { audioInfo, captionStyle, sceneInfoArray, settings, timelineInfo } = project.storyInfo;

        // await refreshTemplateData()
        
        dispatch(clearAndApplyNewProject({ audioInfo, captionStyle, sceneInfoArray, settings, timelineInfo }))

        navigate(`/edit/`);
    }

    return (
          <div style={{height: '92vh', padding: '1rem'}}>
              <h5 style={{marginLeft: 20}}>My Videos</h5>
              <div>
                  {
                      projects.map((project, index) => 
                           ( <div style={{display: 'inline-block', margin: 20}}>
                                <div style={{height: 171, width: 304, backgroundColor: '#ddd', display: 'flex', justifyContent: 'center', alignItems: 'center', overflowX: 'clip', borderRadius: 5, cursor: 'pointer', marginBottom: 10}} onClick={() => openProject(index)}>
                                    <img src={project?.thumbnail} height='100%'></img>
                                </div>
                                <div style={{fontSize: 15, marginLeft: 5, color: '#aaa'}}>{project?.storyInfo?.projectName ?? 'Untitled Project'}</div>
                            </div>
                           )  
                      )
                  }
              </div>
          </div>  
    )
}

export default ProjectView
