import React from 'react'
import Dialog from '@mui/material/Dialog';
import { DialogContent } from '@mui/material'
import Preview from './Preview';

const PreviewModalView = ({open, handleClose}) => {

    return (
        <>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth='lg'
            >
                <DialogContent style={{position: 'relative'}}>
                    <div style={{}}>
                        <Preview />
                    </div>
                   
                    <div style={{position: 'absolute', right: 15, top: 15, fontSize: 25, cursor: 'pointer' }} onClick={handleClose}><i class="bi bi-x-lg"></i></div>
                </DialogContent>
        
            </Dialog>
    
        </>
    )
}

export default PreviewModalView
