import React, {useState, useEffect} from 'react'
import Dropdown from './Dropdown';
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight';
import ColorPicker from './ColorPicker';
import { useDispatch, useSelector } from 'react-redux'
import { setCaptionStyle } from '../redux/storySlice'
import { fontOptions, fontStyleOptions, getFontTypeFromWeight } from '../constants/fontsConfig';
import { captions } from '../constants/captionStyles'; 

const CaptionSettings = () => {
    const dispatch = useDispatch()

    const [captionOptions, setCaptionOptions] = useState()
    const [selectedCaptionIdx, setSelectedCaptionIdx] = useState(0)
    const [currentActiveFont, setCurrentActiveFont] = useState('Roboto')

    const captionStyle = useSelector(
        (state) => state.storyReducer.captionStyle
    )

    useEffect(() => {
        console.log('CAPTION OPTIONS CHANGED')
        if(!captionOptions){
            setCaptionOptions(captionStyle)
        }else{
            console.log('caption options: ', captionOptions)
            dispatch(setCaptionStyle({captionStyle: captionOptions}))
        }
    }, [captionOptions])
    


    const fontSizeOptions = [
        { value: 12, label: '12' },
        { value: 14, label: '14' },
        { value: 16, label: '16' },
        { value: 18, label: '18' },
        { value: 21, label: '21' },
        { value: 24, label: '24' },
        { value: 28, label: '28' },
        { value: 32, label: '32' },
        { value: 36, label: '36' },
        { value: 42, label: '42' },
        { value: 48, label: '48' },
        { value: 56, label: '56' },
        { value: 64, label: '64' },
        { value: 72, label: '72' },
        { value: 80, label: '80' },
        { value: 88, label: '88' },
        { value: 96, label: '96' },
        { value: 104, label: '104' },
        { value: 120, label: '120' },
        { value: 136, label: '136' },
        { value: 148, label: '148' },
    ]

    const onFontFamilyChange = (fontFamily) => {
        console.log('Changing fontSize: ', fontFamily.value)
        var styleInfo = {'fontFamily': fontFamily.value}
        setCaptionOptions({...captionOptions, fontFamily: fontFamily.value})
        // setCurrentActiveFont(fontFamily.value)
        // updateTextStyleInfo(styleInfo)
       
    }

    const onFontSizeChange = (fontSize) => {
        console.log('Changing fontSize: ', fontSize.value)
        var styleInfo = {'fontSize': fontSize.value}
        setCaptionOptions({...captionOptions, fontSize: fontSize.value})
        // updateTextStyleInfo(styleInfo)
        
    }

    const onAlignChange = (pos) => {
        var styleInfo = {'textAlign': pos}
        // updateTextStyleInfo(styleInfo)
        setCaptionOptions({...captionOptions, textAlign: pos})
    }

    const onFontWeightChange = (option) => {
        console.log('Changing fontWeight: ', option.value)
        var styleInfo = {'fontWeight': option.fontWeight, 'fontStyle': option.fontStyle}
        setCaptionOptions({...captionOptions, fontWeight: option.fontWeight, fontStyle: option.fontStyle})
        // updateTextStyleInfo(styleInfo)
    }

    const selectCaption = (caption) => {
        setSelectedCaptionIdx(caption.id)
        setCaptionOptions(caption.style)
    }

    return (
        <div style={{height: '100%'}}>
            <div style={{fontSize: 18, fontWeight: 700, width: '95%', margin: 'auto', marginTop: 20, marginLeft: 20, color: '#424242'}}>Captions </div>
            <div style={{ width: '95%', margin: 'auto', marginTop: 30}}>
                {
                    captions.map((caption, index) => 
                        <div style={{display: 'inline-block', width: 155, height: 91, margin: 5, borderRadius: 15, border: index === selectedCaptionIdx ? '3px solid #5290D6': null,  cursor: 'pointer'}} onClick={() => selectCaption(caption)}>
                            {/* <div style={{position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)'}}>
                                Caption {index}
                            </div> */}
                                <video
                                    autoPlay  
                                    muted
                                    no-controls
                                    loop
                                    style={{ margin: '0 auto',  width: '100%', border: '1px solid #eee', borderRadius: 15}}
                                    src={caption.url}
                                />
                           
                        </div>
                    )
                }
                
            </div>
            <div style={{width: '90%', margin: 'auto', marginTop: 30}}>
                {/* <div style={{fontSize: 18, fontWeight: 700, width: '95%', margin: 'auto', marginTop: 20, marginBottom: 20, color: '#424242'}}> Caption Style</div> */}
                <div style={{marginBottom: 5, width: '60%', display: 'inline-block'}}>
                    <Dropdown
                        isSearchable
                        menuSize={200}
                        placeHolder={captionStyle?.fontFamily ? captionStyle.fontFamily : 'Roboto'}
                        options={fontOptions()}
                        onChange={(value) => onFontFamilyChange(value)}
                    />
                </div>
            
                <div style={{marginBottom: 5, marginLeft: 8, width: '25%', display: 'inline-block'}}>
                    <Dropdown
                        isSearchable={false}
                        menuSize={90}
                        placeHolder={captionStyle?.fontSize ? captionStyle.fontSize : fontSizeOptions[0].value}
                        options={fontSizeOptions}
                        onChange={(value) => onFontSizeChange(value)}
                    />
                </div>
                {/* <div style={{ marginLeft: 8, height: 32, width: 32, paddingTop: 2, paddingLeft: 2, backgroundColor: 'black', display: 'inline-block'}}>
                    <ColorPicker type='Stroke' /> 
                </div> */}
                <div style={{marginBottom: 8, width: '50%', display: 'inline-block'}}>
                    <Dropdown
                        isSearchable={false}
                        menuSize={150}
                        placeHolder={getFontTypeFromWeight(captionStyle?.fontWeight, captionStyle?.fontStyle)}
                        options={fontStyleOptions(currentActiveFont)}
                        onChange={(value) => onFontWeightChange(value)}
                    />
                </div>
                <div style={{border: '1px solid #eee', backgroundColor: '#fbfbfb', borderRadius: 4, display: 'inline-block', marginLeft: 8}}>
                    <div style={{padding: 6, cursor: 'pointer', display: 'inline-block'}} onClick={() => onAlignChange('left')}>
                        <FormatAlignLeftIcon style={{fontSize: 20}} />
                    </div>
                    <div style={{padding: 6, cursor: 'pointer', display: 'inline-block'}} onClick={() => onAlignChange('center')}>
                        <FormatAlignCenterIcon style={{fontSize: 20}}/>
                    </div>
                    <div style={{padding: 6, cursor: 'pointer', display: 'inline-block'}} onClick={() => onAlignChange('right')}>
                        <FormatAlignRightIcon style={{fontSize: 20}}/>
                    </div>
                </div>
            
                
                <hr style={{border: '1px solid #eee', marginTop: 10, marginBottom: 10}} />
                {/* <div className='toolbox-section-header'>Color</div> */}
                <div style={{display: 'flex'}}>
                    <div style={{display: 'inline-block', width: '40%', marginRight: '8%'}}>
                        <div style={{marginBottom: 5}}>Text</div>
                        <ColorPicker type='Stroke' pos='top' mode='caption'/>
                    </div>
                    <div style={{display: 'inline-block', width: '40%'}}>
                        <div style={{marginBottom: 5}}>Background</div>
                        <ColorPicker type='Fill' pos='top' mode='caption'/>
                    </div>
                    
                </div>
            </div>
           
        </div>
    )
}

export default CaptionSettings
