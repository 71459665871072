import React, {useState, useEffect} from 'react'
import '../css/editor.css';
import { useDispatch, useSelector } from 'react-redux'
import UploadBox from './UploadBox'
import ReactLoading from 'react-loading';
import { updateActionLog } from '../redux/storySlice'
import { authUser } from '../constants/data';
import { db } from "../configs/firebaseConfig";
import { actionType, actionStatus } from '../utils/actionManager';
import {
  collection,
  query,
  orderBy,
  onSnapshot,
} from "firebase/firestore";

const UploadView = () => {
    const dispatch = useDispatch()
    const [mediaItems, setMediaItems] = useState()
    const [loading, setLoading] = useState(true)

    const selectedObjectId = useSelector(
        (state) => state.storyReducer.selectedObjectId
    )

    const actionLog = useSelector(
        (state) => state.storyReducer.actionLog
    )

    const activeSceneIndex = useSelector(
        (state) => state.storyReducer.activeSceneIndex
    )

    const sceneInfo = useSelector(
        (state) => state.storyReducer.sceneInfoArray
    )

    const timeline = useSelector(
        (state) => state.storyReducer.timelineInfo
    )

    useEffect(() => {
        if(mediaItems){
            setLoading(false);
        }
    }, [mediaItems])

    useEffect(() => {
        var unsubscribe;
        if(authUser){
            const collectionRef = collection(db, "users", authUser.uid, "assets");
            const querySnapshot = query(collectionRef, orderBy("created", "desc"));
           
            console.log("Query Snapshot: ", querySnapshot);
            unsubscribe = onSnapshot(querySnapshot, (docs) => {
                let assets = [];
                docs.forEach((doc) => {
                    assets.push({ ...doc.data(), id: doc.id });
                });
                updateMediaItems(assets);
            });
            
        }
        
        return () => {
            if(authUser){unsubscribe()}
        } 
    }, [])

    const updateMediaItems = (assets) => {
        console.log('UPDATING MEDIA ITEMS: ', assets)
        setMediaItems(assets)
    }

    const isVideoAvailableForScene = () => {
        var scene = sceneInfo[activeSceneIndex]
        var objs = timeline.filter((obj) => {
            return scene.start >= obj.startTime && scene.end <= obj.endTime;
        });

        console.log('SCENE: ', scene)
        console.log('OBJS:==> ', objs)
        var isVideoAvailable = false
       
        objs.map((obj) => {
            if(obj.type == 'video'){
                isVideoAvailable = true
            }
        })
        console.log(`VIDEO AVAILABLE FOR SCENE ${scene.id} ::`, isVideoAvailable)
        return isVideoAvailable;
      
    }

    const handleMediaOptionClick = (obj) => {
        // Replace Media only if Media Object is selected
        console.log('Selected OBject: ', selectedObjectId)
        var scene = sceneInfo[activeSceneIndex]

    
        if(selectedObjectId != null){
            console.log('Selected Media Info: ', obj)

            console.log('Action Log: ', actionLog)
            var actionLogRef = actionLog
    
            const action = {
                type: actionType.REPLACE_MEDIA,
                target: selectedObjectId,
                info: {...obj, created: null},
                status: actionStatus.PROCESS
            }
    
            dispatch(
                updateActionLog({actionLog: [action, ...actionLogRef]})
            )
        }else if(!isVideoAvailableForScene()){
            console.log('Selected Media Info: ', obj)

            console.log('Action Log: ', actionLog)
            var actionLogRef = actionLog
    
            const action = {
                type: actionType.ADD_MEDIA,
                target: activeSceneIndex,
                info: {...obj, id: scene.id, type: 'video', sceneId: scene.id, startTime: scene.start, endTime: scene.end, created: null},
                status: actionStatus.PROCESS
            }
    
            dispatch(
                updateActionLog({actionLog: [action, ...actionLogRef]})
            )
        }
       
    }

    return (
        <div style={{height: '100%'}}>
            
            <UploadBox setLoading={setLoading}/>
            {
                loading ? (
                    <ReactLoading type='spokes' color="#988AF1" height={16} width={16} />
                )
                
                : (
                    <div className='toolbox-items-container'>
                      
                        {
                            mediaItems && mediaItems.map((obj, index) =>   
                                <img
                                    crossOrigin="anonymous"
                                    data-id={index}
                                    className='media-item'
                                    src={obj.thumbnail}
                                    onClick={() => handleMediaOptionClick(obj)}
                                >
                                </img>)
                        }
                    </div>
                )
            }
        </div>
    )
}

export default UploadView
