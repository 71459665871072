import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

const SceneOptions = () => {
    const dispatch = useDispatch()

    const activeSceneIndex = useSelector(
        (state) => state.storyReducer.activeSceneIndex
    )

    return (
        <div style={{width: '100%', marginTop: 10}}>
            <span style={{fontSize: 16,  fontWeight: 600, margin: 'auto', marginTop: 10, marginLeft: 20, color: '#424242'}}>
                Scene {activeSceneIndex+1}
            </span>
        </div>
    )
}

export default SceneOptions
